<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    MemberTypeGets(){
        return axios.get(Vue.prototype.$bkURL + "membertypes");
    },
    MemberTypeAdd(mbt){
        return axios.post(Vue.prototype.$bkURL + "membertype", mbt);
    },
    MemberTypeUpdate(mbt){
        return axios.patch(Vue.prototype.$bkURL + "membertype", mbt);
    },
    MemberTypeDelete(mbt_id){
        return axios.delete(Vue.prototype.$bkURL + "membertype?id="+mbt_id);
    }
}
</script>