<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    MemberTypeGets(){
        return axios.get(Vue.prototype.$bkURL + "membertypes");
    },
    ChurchGets(){
        return axios.get(Vue.prototype.$bkURL + "churchs");
    },
    RespChurchGets(){
        return axios.get(Vue.prototype.$bkURL + "respchurchs");
    },
    VocalGets(){
        return axios.get(Vue.prototype.$bkURL + "vocals");
    }
}
</script>