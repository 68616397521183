<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    MemberGets(){
        return axios.get(Vue.prototype.$bkURL + "Members");
    },
    MemberAdd(mb){
        return axios.post(Vue.prototype.$bkURL + "Member", mb);
    },
    MemberUpdate(mb){
        return axios.patch(Vue.prototype.$bkURL + "Member", mb);
    },
    MemberDelete(mbid){
        return axios.delete(Vue.prototype.$bkURL + "Member?id="+mbid);
    },
    MemberNewMBCode(){
        return axios.get(Vue.prototype.$bkURL + "Member/newmbcode");
    },
    // ExportMember(){
    //     return axios.get(Vue.prototype.$bkURL + "Export/member", {responseType:"blob"});
    // },
    ExportMember(){
        return axios.get(Vue.prototype.$bkURL + "Export/member");
    }
}
</script>