<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    choirministryGets(){
        return axios.get(Vue.prototype.$bkURL + "choirministries");
    },
    choirministryGetbyName(clname){
        return axios.get(Vue.prototype.$bkURL + "choirministry/name?key="+clname);
    },
    choirministryAdd(cl){
        return axios.post(Vue.prototype.$bkURL + "choirministry", cl);
    },
    choirministryUpdate(cl){
        return axios.patch(Vue.prototype.$bkURL + "choirministry", cl);
    },
    choirministryDelete(clid){
        return axios.delete(Vue.prototype.$bkURL + "choirministry?id="+clid);
    },
    choirministryBannerAdd(fdata){
        return axios.post(Vue.prototype.$bkURL + "choirministry/banner", fdata);
    },
    choirministryGalleryGet(cmid){
        return axios.get(Vue.prototype.$bkURL + "choirministry/gallery?id="+cmid);
    },
    choirministryGalleryAdd(fdata){
        return axios.post(Vue.prototype.$bkURL + "choirministry/gallery", fdata);
    },
    choirministryGalleryDelete(cmid,item){
        return axios.delete(Vue.prototype.$bkURL + "choirministry/gallery?id="+cmid+"&item="+item);
    }
}
</script>