<template>
    <div>
        <!-- Main Content -->
        <div class="container-fluid">
            <!-- Header Menu -->
            <div class="row">
                <div class="col-sm-12">
                    <h6 class="h2 d-inline-block text-dark mt-2 mb-0">
                        <i :class="'ni '+PageIcon+' mr-1'"></i>{{PageTitle}}
                    </h6>

                    <base-button class="ml-2 mb-3" size="sm" type="info" @click="NewItem()">
                        <i class="fa fa-plus-circle"></i>{{MenuNewLabel}}
                    </base-button>

                    <base-button class="ml-2 mb-3" size="sm" type="outline-success" @click="ReqExportMember()">
                        <i class="fa fa-save"></i>Export
                    </base-button>
                </div>
            </div>

            <!-- Table Data -->
            <div class="row mt-2">
                <div class="col-sm-12">
                    <datagrid
                        v-bind:showscoll="true"
                        v-bind:header_nowarp="true"
                        v-bind:showfilter="true"
                        v-bind:rowdbclick_enable="false"
                        v-bind:showPagination="true"
                        v-bind:ShowSort="true"
                        v-bind:HeaderStick="TBHeaderStick"
                        v-bind:isBusy="TBBusy"
                        v-bind:perPage="TBRowPerPage"
                        v-bind:datas="Items"
                        v-bind:cols="Fields"
                    />
                </div>
            </div>
        </div>

        <!-- Modal -->
        <modal :show="ModalEditOpen" size="lg">
            <div class="container-fluid">
                <h4 class="text-dark mb-1">{{ModalEditTitle}} Add/Edit</h4>

                <div class="row">
                    <div class="col-md-6">
                        <base-input label="Member Type">
                            <select class="form-control" v-model="EditItem.mbtype_id">
                                <option v-for="(op, index) in optMBType" :key="index"
                                    v-bind:value="op.value"
                                >
                                    {{op.text}}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-md-6">
                        <base-input label="Code" v-model="EditItem.mbcode" readonly></base-input>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-2">
                        <base-input label="Title" v-model="EditItem.title" ></base-input>
                    </div>
                    <div class="col-md-5">
                        <base-input label="Name" v-model="EditItem.name" ></base-input>
                    </div>
                    <div class="col-md-5">
                        <base-input label="Surname" v-model="EditItem.surname" ></base-input>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <base-input label="Nickname" v-model="EditItem.nickname" ></base-input>
                    </div>
                    <div class="col-md-6">
                        <base-input type="date" label="Expire Date" v-model="EditItem.expdate" ></base-input>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-5">
                        <base-input type="date" label="Birth Date" v-model="EditItem.birthdate" ></base-input>
                        <base-input label="Address">
                            <textarea class="form-control"  v-model="EditItem.addr" rows="2"></textarea>
                        </base-input>
                    </div>

                    <div class="col-md-7">
                        <div class="row">
                            <label class="form-control-label col-sm-3 pull-right">Phone</label>
                            <div class="col-sm-9">
                                <base-input v-model="EditItem.phone" ></base-input>
                            </div>
                        </div>

                        <div class="row">
                            <label class="form-control-label col-sm-3 pull-right">Email</label>
                            <div class="col-sm-9">
                                <base-input v-model="EditItem.email" ></base-input>
                            </div>
                        </div>

                        <div class="row">
                            <label class="form-control-label col-sm-3 pull-right">LineId</label>
                            <div class="col-sm-9">
                                <base-input v-model="EditItem.lineid" ></base-input>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-sm-4">
                        <base-input label="Church">
                            <select class="form-control" v-model="EditItem.church_id">
                                <option v-for="(op, index) in optChurch" :key="index"
                                    v-bind:value="op.value"
                                >
                                    {{op.text}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-sm-4">
                        <base-input label="Responsibility">
                            <select class="form-control" v-model="EditItem.rbch_id">
                                <option v-for="(op, index) in optRBCH" :key="index"
                                    v-bind:value="op.value"
                                >
                                    {{op.text}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-sm-4">
                        <base-input label="Vocal">
                            <select class="form-control" v-model="EditItem.vocal_id">
                                <option v-for="(op, index) in optVocal" :key="index"
                                    v-bind:value="op.value"
                                >
                                    {{op.text}}
                                </option>
                            </select>
                        </base-input>
                    </div>                    
                </div>

                <div class="row mt-2 justify-content-end">
                    <base-button @click="SaveItem()" type="success"><i class="fa fa-save"></i> Save</base-button>
                    <base-button @click="CancelSaveItem()" type="dark"><i class="fa fa-times"></i> Close</base-button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import BaseButton from '../components/BaseButton.vue';
import datagrid from "../component_thaicma/DataGrid.vue";
import MemberService from "../services/MemberService.vue";
import FoundationService from "../services/FoundationService.vue";

export default {
    components: {
        datagrid,
        BaseButton
    },
    data(){
        return{
            PageTitle: "Member Manage",
            PageIcon: "ni-single-02 text-success",            
            MenuNewLabel: "New Member",
            TBHeaderStick: "70vh",
            TBBusy: false,
            TBRowPerPage: 100,
            Items:[],
            Fields:[
                { key: "mbid", label: "CMD" },
                { key: "mbcode", label: "Code" },
                { key: "title", label: "Title" },
                { key: "name", label: "Name" },
                { key: "surname", label: "Surname" },
                { key: "nickname", label: "Nickname" },                
                { key: "mbtype", label: "Type" },
                { key: "addr", label: "Address" },
                { key: "phone", label: "Phone" },
                { key: "email", label: "Email" },
                { key: "lineid", label: "LineID" },
                { key: "church", label: "Church" },
                { key: "rbch", label: "Responsibility" },
                { key: "vocal", label: "Vocal" },
                { key: "birthdate", label: "Birth Date" },
                { key: "expdate", label: "Expire Date" },                
            ],
            EditItem:{
                mbtype_id: null,
                mbcode:"",
                title: "",
                name: "",
                surname: "",
                nickname: "",
                addr: "",
                phone: "",
                email: "",
                lineid: "",
                church_id: null,
                rbch_id: null,
                vocal_id: null,
                expdate: null,
                birthdate: null
            },
            KeyItem: "mbid",
            ModalEditTitle: "Member",
            ModalEditOpen: false,
            SubKeys:[
                "title",
                "name",
                "surname"
            ],
            optMBType:[
                { value:null, text: "--Select Type--" }
            ],
            optVocal:[
                { value:null, text: "--Select Vocal--" }
            ],
            optChurch:[
                { value:null, text: "--Select Church--" }
            ],
            optRBCH:[
                { value:null, text: "--Select Responsibility--" }
            ]
        }
    },
    methods:{
        GetItemLists(){
            MemberService.MemberGets()
                .then(res => {
                    this.Items = [];
                    this.Items = res.data;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        NewItem(){
            this.EditItem = {
                mbtype_id: null,
                mbcode:"",
                title: "",
                name: "",
                surname: "",
                nickname: "",
                addr: "",
                phone: "",
                email: "",
                lineid: "",
                church_id: null,
                rbch_id: null,
                vocal_id: null,
                expdate: null
            };

            MemberService.MemberNewMBCode()
                .then(res => {
                    this.EditItem.mbcode = res.data.NewMBCode;
                })
                .catch(err =>{
                    console.log(err);
                });

            this.ModalEditOpen = true;
        },
        ValidateData(){
            let IsOK = false;
            let Notice = "";

            if(this.EditItem.mbtype_id == null)
                Notice += "Please insert <b class='text-danger'>Member Type</b><br/>";

            if(this.EditItem.title == "")
                Notice += "Please insert <b class='text-danger'>Title</b><br/>";
            
            if(this.EditItem.name == "")
                Notice += "Please insert <b class='text-danger'>Name</b><br/>";

            if(this.EditItem.surname == "")
                Notice += "Please insert <b class='text-danger'>Surname</b><br/>";

            if(this.EditItem.expdate == null)
                Notice += "Please insert <b class='text-danger'>Expire Date</b><br/>";

            if(Notice != ""){
                this.$swal({
                    title: this.ModalEditTitle +" invalid data!!!",
                    html: Notice,
                    icon: "warning"
                })
                IsOK = false;
            }
            else{
                IsOK = true;
            }

            return IsOK;
        },
        SaveItem(){
            let IsOK = this.ValidateData();

            if(IsOK == true){
                if(this.EditItem[this.KeyItem] == null || this.EditItem[this.KeyItem] == ""){
                    MemberService.MemberAdd(this.EditItem)
                        .then(res => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.ModalEditOpen = false;
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
                else{
                    MemberService.MemberUpdate(this.EditItem)
                        .then(res => {
                            this.$swal({
                                title: "Update "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.ModalEditOpen = false;
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Update "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
            }
        },
        CancelSaveItem(){
            this.ModalEditOpen = false;
        },
        DeleteItem(ItemKey){
            MemberService.MemberDelete(ItemKey)
                        .then(res => {
                            this.$swal({
                                title: "Delete "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Delete "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
        },
        GridEditData(EditData){
            this.EditItem = JSON.parse(JSON.stringify(EditData));
            this.EditItem.expdate = this.DateToInput(this.EditItem.expdate);
            this.ModalEditOpen = true;
        },
        GridDeleteData(DeleteItem){
            let subdata = "";
            for(let i = 0; i < this.SubKeys.length; i++){
                let sk = this.SubKeys[i];
                subdata += " "+DeleteItem[sk];
            }

            this.$swal({
                title: this.ModalEditTitle + ' Delete',
                html: '<h5>Are you sure to delete<b class="text-danger">'+subdata+'</b>?</h5>',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                cancelButtonColor: '#000',
                confirmButtonText: '<i class="fa fa-trash"> Delete</i>'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let ItemKey = DeleteItem[this.KeyItem];
                    this.DeleteItem(ItemKey);
                }
            });
        },
        GetMemberTypeLists(){
            let firstOpt = { value:null, text: "--Select Type--" };
            this.optMBType = [];
            this.optMBType.push(firstOpt);
            FoundationService.MemberTypeGets()
                .then(res => {
                    let FDs = res.data;
                    let opt = this.ListToOpt(FDs, "mbtype_id", "typename", firstOpt);
                    this.optMBType = opt;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        ListToOpt(IList, FieldValue, FieldText, firstOpt){
            let result = [];
            if(firstOpt != null)
                result.push(firstOpt);

            for(let i = 0; i < IList.length; i++){
                let IT = IList[i];
                let opt = {
                    value: (FieldValue == null? IT : IT[FieldValue]),
                    text: IT[FieldText]
                };
                result.push(opt);
            }
            return result;
        },
        GetChurchList(){
            let firstOpt = { value:null, text: "--Select Church--" };
            this.optChurch = [];
            this.optChurch.push(firstOpt);
            FoundationService.ChurchGets()
                .then(res => {
                    let FDs = res.data;
                    let opt = this.ListToOpt(FDs, "church_id", "churchname", firstOpt);
                    this.optChurch = opt;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        GetRespChurchList(){
            let firstOpt = { value:null, text: "--Select Responsibility--" };
            this.optRBCH = [];
            this.optRBCH.push(firstOpt);
            FoundationService.RespChurchGets()
                .then(res => {
                    let FDs = res.data;
                    let opt = this.ListToOpt(FDs, "rbch_id", "rcname", firstOpt);
                    this.optRBCH = opt;
                })
                .catch(err => {
                    console.log(err);
                });            
        },
        GetVocalList(){
            let firstOpt = { value:null, text: "--Select Vocal--" };
            this.optVocal = [];
            this.optVocal.push(firstOpt);
            FoundationService.VocalGets()
                .then(res => {
                    let FDs = res.data;
                    let opt = this.ListToOpt(FDs, "vocal_id", "name", firstOpt);
                    this.optVocal = opt;
                })
                .catch(err => {
                    console.log(err);
                });               
        },
        DateToInput(dt){
            let result = null;

            try{
                let Temp = new Date(dt);
                // yyyy-MM-dd
                let year = Temp.getFullYear();
                let month = ('0' + (Temp.getMonth() + 1)).slice(-2);
                let date = ('0' + (Temp.getDate())).slice(-2);

                result = year+"-"+month+"-"+date;
                // console.log(result);
            }
            catch{
                result = null;
            }
            
            return result;
        },
        ReqExportMember(){
            MemberService.ExportMember()
                .then(res => {
                    // res.download('test.csv');
                    // console.log(res);
                    // const type = res.headers['content-type']
                    // const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]),res.data], { type: type, encoding: 'UTF-8' })
                    // const link = document.createElement('a')
                    // link.href = window.URL.createObjectURL(blob)
                    let link_downlod = 'https://api.thaicma.org/'+res.data;
                    console.log(link_downlod);
                    const link = document.createElement('a');
                    link.href = link_downlod;
                    link.download = 'member.csv';
                    link.click();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },
    mounted(){
        this.GetItemLists();
        this.GetChurchList();
        this.GetRespChurchList();
        this.GetVocalList();
        this.GetMemberTypeLists();
    }
}
</script>

<style scoped>

</style>