<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    SiteContentGet(lang){
        return axios.get(Vue.prototype.$bkURL + "sitecontent?lang="+lang);
    },
    SiteContentUpdate(sc){
        return axios.patch(Vue.prototype.$bkURL + "sitecontent", sc);
    },
    SiteContentDefalutLang(lang){
        return axios.patch(Vue.prototype.$bkURL + "sitecontent/deflang?lang="+lang);
    },
    SiteContentCoverAdd(fdata){
        return axios.post(Vue.prototype.$bkURL + "sitecontent/cover", fdata);
    }
}
</script>