<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    RespChurchGets(){
        return axios.get(Vue.prototype.$bkURL + "respchurchs");
    },
    RespChurchAdd(rbch){
        return axios.post(Vue.prototype.$bkURL + "respchurch", rbch);
    },
    RespChurchUpdate(rbch){
        return axios.patch(Vue.prototype.$bkURL + "respchurch", rbch);
    },
    RespChurchDelete(rbch_id){
        return axios.delete(Vue.prototype.$bkURL + "respchurch?id="+rbch_id);
    }
}
</script>