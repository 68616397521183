<template>
    <div>
        <!-- Main Content -->
        <div class="container-fluid">
            <!-- Header Menu -->
            <div class="row">
                <div class="col-sm-12">
                    <h6 class="h2 d-inline-block text-dark mt-2 mb-0">
                        <i :class="'ni '+PageIcon+' mr-1'"></i>{{PageTitle}}
                    </h6>

                    <base-button class="ml-2 mb-3" size="sm" type="info" @click="NewItem()">
                        <i class="fa fa-plus-circle"></i>{{MenuNewLabel}}
                    </base-button>
                </div>
            </div>

            <!-- Table Data -->
            <div class="row mt-2">
                <div class="col-sm-3" v-for="(bg,index) in Items" :key="index">
                    <bg-card
                        v-bind:Name="bg.name"
                        v-bind:Description="bg.description"
                        v-bind:EventDate="bg.eventdate"
                        v-bind:Pic="bg.pic"
                        v-bind:MenuEnable="true"
                        v-bind:Item="bg"
                    >

                    </bg-card>
                </div>
            </div>
        </div>

        <!-- Modal Edit -->
        <modal :show="ModalEditOpen" size="lg" id="modal-edit">
            <div class="container-fluid">
                <h4 class="text-dark mb-1">{{ModalEditTitle}} Add/Edit</h4>

                <div class="row">
                    <div class="col-sm-4 text-center">
                        <label class="form-control-label">Pic</label>
                        <img :src="EditItem.pic != '' && EditItem.pic != null ? GetFullImageUrl(EditItem.pic) : NoImagePath" class="img w-100"/>
                        <input type="file" @change="PreviewFileChange" class="form-control" accept="image/png, image/jpeg">
                    </div>
                    <div class="col-sm-8">
                        <div class="row">
                            <label class="col-sm-3 col-form-label form-control-label pull-right">Name</label>
                            <div class="col-sm-9">
                                <base-input v-model="EditItem.name"></base-input>
                            </div>
                        </div>
                        
                        <div class="row">
                            <label class="col-sm-3 col-form-label form-control-label pull-right">Description</label>
                            <div class="col-sm-9">
                                <textarea class="form-control"  v-model="EditItem.description" rows="2"></textarea>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <label class="col-sm-3 col-form-label form-control-label pull-right">Eventdate</label>
                            <div class="col-sm-9">
                                <base-input type="date" v-model="EditItem.eventdate"></base-input>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-sm-7">
                                <label class="form-control-label col-sm-12">Content Mode</label>
                                <b-form-group class="ml-4">
                                    <b-form-radio v-model="EditItem.descmode" name="rdo-contentmode" value="external">External URL</b-form-radio>
                                    <b-form-radio v-model="EditItem.descmode" name="rdo-contentmode" value="local">Local Content</b-form-radio>
                                </b-form-group>
                            </div>

                            <div class="col-sm-5">
                                <base-input label="Index" type="number" v-model.number="EditItem.idx" />
                            </div>
                        </div>

                        <div class="row" v-if="EditItem.descmode == 'local' && EditItem != null">
                            <div class="col-sm-12 text-center">
                                <base-button type="info" @click="ShowEditImageGallery()"><i class="fas fa-image"></i> Gallery Edit</base-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h5 class="form-control-label text-gray">
                            {{EditItem.descmode == 'external' ? "Content Link" : "Local Content"}}

                            <base-button v-if="EditItem.descmode != 'external'" size="sm" class="float-right" @click="ShowPreviewLocalContent = !ShowPreviewLocalContent">
                                {{ShowPreviewLocalContent? 'Hide' : 'Show'}} Preview
                            </base-button>
                        </h5>                        
                    </div>
                </div>

                <div class="row" v-if="EditItem.descmode != 'local'">
                    <div class="col-sm-12">
                        <div class="form-group row">
                            <div class="col-sm-3">
                                <label class="col-form-label form-control-label float-right">External URL</label>
                            </div>
                            <div class="col-sm-9">
                                <base-input v-model="EditItem.exturl"></base-input>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="row mb-1" v-else>
                    <div class="col-sm-12 text-center">
                        <div class="row">
                            <div :class="ShowPreviewLocalContent ? 'col-sm-6' : 'col-sm-12'">
                                <vue-editor v-model="EditItem.htmldesc" :customModules="customModulesForEditor" :editorOptions="editorSettings" />
                            </div>
                            <div v-if="ShowPreviewLocalContent == true" class="col-sm-6">
                                <div v-html="EditItem.htmldesc"></div>
                            </div>
                        </div>
                    </div>                    
                </div>


                <div class="row mt-1 justify-content-end">
                    <base-button @click="SaveItem()" type="success"><i class="fa fa-save"></i> Save</base-button>
                    <base-button @click="CancelSaveItem()" type="dark"><i class="fa fa-times"></i> Close</base-button>
                </div>
            </div>
        </modal>

        <!-- Modal Gallery -->
        <modal :show="ModalEditGallery" size="xl">
            <div class="container-fluid">
                <h4 class="text-dark mb-1">Gallery <base-button class="float-right" @click="CloseGalleryEdit()" type="light"><i class="fa fa-times"></i></base-button></h4>

                <div class="row">
                    <div class="col-sm-3" v-for="(eg, inx) in EditGalleries" :key="inx">
                        <card>
                            <img :src="eg.pic != '' && eg.pic != null ? GetFullImageUrl(eg.pic) : NoImagePath" class="img img-gallery w-90"/>
                            <input v-if="eg.pic == '' || eg.pic == null" type="file" @change="GalleryFileChange" class="form-control mt-1" accept="image/png, image/jpeg">
                            <base-button v-if="eg.pic != '' && eg.pic != null" @click="GalleryDeleteImage(eg.item)" type="danger" size="sm" class="float-right"><i class="fa fa-trash"></i></base-button>
                        </card>
                    </div>                    
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import BaseButton from '../components/BaseButton.vue';
import BlogService from '../services/BlogService.vue';
import BlogCard from '../component_thaicma/BlogCard.vue';
import EventBus from '../Event-Bus.vue';
import { VueEditor } from 'vue2-editor';
import ImageResize from "quill-image-resize-vue";
import Modal from '../components/Modal.vue';
import Card from '../components/Cards/Card.vue';

export default {
    components: {
        BaseButton,
        "bg-card":BlogCard,
        VueEditor,
        Modal,
        Card
    },
    data(){
        return{
            PageTitle: "News Manage",
            PageIcon: "ni ni-collection",            
            MenuNewLabel: "New News",
            Items:[],
            EditItem:{
                name: "",
                description: "",
                eventdate: null,
                pic: null,
                htmldesc: "",
                descmode: "external",
                exturl: "",
                idx: 1
            },
            EditGalleries:[
                { bid: null, item: null, pic: null }
            ],
            KeyItem: "bid",
            ModalEditTitle: "News",
            ModalEditOpen: false,
            SubKeys:[
                "name"
            ],
            NoImagePath: require("@/assets/img/image_not_available.png"),
            ShowPreviewLocalContent:false,
            customModulesForEditor: [
                { alias: "imageResize", module: ImageResize }
            ],
            editorSettings: {
                modules: {
                    imageResize: {}
                }
            },
            ModalEditGallery:false
        }
    },
    methods:{
        GetItemLists(){
            BlogService.BlogGets()
                .then(res => {
                    this.Items = [];
                    this.Items = res.data;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        NewItem(){
            this.EditItem ={
                name: "",
                description: "",
                eventdate: null,
                pic: null,
                htmldesc: "",
                descmode: "external",
                exturl: "",
                idx: 1
            };
            if(this.Items.length > 0){
                let LastIdx = Math.max.apply(Math, this.Items.map(function(f) { return f.idx; }));
                this.EditItem.idx = LastIdx + 1;
            }
            this.ModalEditOpen = true;
        },
        ValidateData(){
            let IsOK = false;
            let Notice = "";

            if(this.EditItem.name == "")
                Notice += "Please insert <b class='text-danger'>Name</b><br/>";

            if(this.EditItem.idx <= 0)
                Notice += "Index must greater than <b class='text-danger'>Zero</b><br/>"      
            
            if(this.EditItem.descmode == "external"){
                if(this.EditItem.exturl == ""){
                    Notice += "Please insert <b class='text-danger'>External URL</b><br/>" 
                }

                if(!this.EditItem.exturl.startsWith('http')){
                    Notice += "External URL invalid!!! plase input like <b class='text-warning'>https://google.com</b> " 
                }
            }                 

            if(Notice != ""){
                this.$swal({
                    title: this.ModalEditTitle +" invalid data!!!",
                    html: Notice,
                    icon: "warning"
                })
                IsOK = false;
            }
            else{
                IsOK = true;
            }

            return IsOK;
        },
        SaveItem(){
            let IsOK = this.ValidateData();

            if(IsOK == true){
                if(this.EditItem[this.KeyItem] == null || this.EditItem[this.KeyItem] == ""){
                    BlogService.BlogAdd(this.EditItem)
                        .then(res => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.ModalEditOpen = false;
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
                else{
                    BlogService.BlogUpdate(this.EditItem)
                        .then(res => {
                            this.$swal({
                                title: "Update "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.ModalEditOpen = false;
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Update "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
            }
        },
        CancelSaveItem(){
            this.ModalEditOpen = false;
        },
        DeleteItem(ItemKey){
            BlogService.BlogDelete(ItemKey)
                        .then(res => {
                            this.$swal({
                                title: "Delete "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Delete "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
        },
        DateToInput(dt){
            let result = null

            try{
                let Temp = new Date(dt);
                
                // yyyy-MM-dd
                let year = Temp.getFullYear();
                let month = ('0' + (Temp.getMonth() + 1)).slice(-2);
                let date = ('0' + Temp.getDate()).slice(-2);

                result = year+"-"+month+"-"+date;
                // console.log(result);
            }
            catch{
                result = null;
            }
            
            return result;
        },
        GetFullImageUrl(img_url){
           return this.$bkURL +"imgs/"+img_url;
        },
        PreviewFileChange(ev){
            console.log(ev);
            let UpFile = ev.target.files[0];
            if(this.EditItem.bid == null){
                let IsOK = this.ValidateData();
                if(IsOK == true){
                    BlogService.BlogAdd(this.EditItem)
                        .then(res => {            
                            console.log(res.data);                                            
                            BlogService.BlogGetbyName(this.EditItem.name)
                                .then(res2 => {
                                    // console.log(res2.data);
                                    let SavedItem = res2.data;
                                    this.EditItem = SavedItem;
                                    this.EditItem.eventdate = this.DateToInput(SavedItem.eventdate);
                                    this.DoUploadFile(UpFile);
                                })
                                .catch(err2 => {
                                    this.$swal({
                                        title: "Save "+this.ModalEditTitle+" Error!!!",
                                        html: err2,
                                        icon: "error"
                                    });
                                });
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
            }
            else{
                this.DoUploadFile(UpFile);
            }            
        },
        DoUploadFile(UpFile){
            let formData  = new FormData();
            formData.append('file', UpFile);
            formData.append('bid', this.EditItem.bid);
            BlogService.BlogBannerAdd(formData)
                .then(res => {
                    this.EditItem.pic = res.data;
                })
                .catch(err => {
                    this.$swal({
                        title: "Save Preview Image Error!!!",
                        html: err,
                        icon: "error"
                    })
                });
        },
        GetImageGallery(){
            this.EditGalleries = [{ bid: null, item: null, pic: null }];
            BlogService.BlogGalleryGet(this.EditItem.bid)
                .then(res => {               
                    let GL = res.data;
                    GL.push({ bid: null, item: null, pic: null }); 
                    this.EditGalleries = GL;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        ShowEditImageGallery(){
            this.ModalEditOpen = false;
            this.GetImageGallery();
            this.ModalEditGallery = true;
        },
        CloseGalleryEdit(){
            this.ModalEditOpen = true;
            this.ModalEditGallery = false;
        },
        GalleryFileChange(ev){
            let UpFile = ev.target.files[0];
            this.DoUploadGallery(UpFile);
        },
        DoUploadGallery(UpFile){
            let formData  = new FormData();
            formData.append('file', UpFile);
            formData.append('bid', this.EditItem.bid);
            BlogService.BlogGalleryAdd(formData)
                .then(res => {
                    // this.EditItem.pic = res.data;
                    this.GetImageGallery();
                })
                .catch(err => {
                    this.$swal({
                        title: "Save Preview Image Error!!!",
                        html: err,
                        icon: "error"
                    })
                });
        },
        GalleryDeleteImage(picitem){
            this.$swal({
                        icon: 'question',
                        title: 'Delete Picture',
                        text: 'Are you sure to picture?',
                        showCloseButton: false,
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonText: '<i class="fa fa-trash"></i> Delete',
                        confirmButtonColor: '#dc3545',  
                        cancelButtonText: '<i class="fa fa-times"></i> Cancel',
                        cancelButtonColor: '#6c757d'
                    })
            .then((result) => {
                if(result.isConfirmed){
                    BlogService.BlogGalleryDelete(this.EditItem.bid, picitem)
                        .then(res => {
                            console.log(res);
                            this.GetImageGallery();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Delete Preview Image Error!!!",
                                html: err,
                                icon: "error"
                            })
                        });
                }
            });
            
        }
    },
    mounted(){
        this.GetItemLists();

        EventBus.$on("BlogReqEdit", (EditData) => {
            // console.log(EditData);
            this.EditItem = JSON.parse(JSON.stringify(EditData));
            this.EditItem.eventdate = this.DateToInput(this.EditItem.eventdate);
            this.ModalEditOpen = true;
        });

        EventBus.$on("BlogReqDelete", (DeleteItem) => {
            // console.log(DeleteItem);
            let ItemKey = DeleteItem[this.KeyItem];
            this.DeleteItem(ItemKey);
        });
    },
    beforeDestroy(){
        EventBus.$off("BlogReqEdit");
        EventBus.$off("BlogReqDelete");
    }
    
}
</script>

<style scoped>
@import "~vue2-editor/dist/vue2-editor.css";

.img-gallery{
    width: 100%;
    height: auto;
}

#modal-edit {
    overflow-y:scroll;
}
</style>