<template>
    <div>
        <div class="card pt-2 pl-2 pr-2">
            <b-table striped hover bordered head-variant="dark" show-empty tbody-tr-class="text-nowarp"
                    @row-dblclicked="RowDBClick" 
                    :fields="fields" 
                    :items="items"
                    :responsive="responsive" 
                    :sticky-header="HeaderStick" 
                    :per-page="perPage" 
                    :busy="isBusy"
                    :filter="filter"
                    :filterIncludedFields="filterOn"
                    :current-page="currentPage"
                    :small="GridSmall"
                    :borderless="GridBorderless">

                <!-- Filter Row -->
                <template slot="top-row" slot-scope="{ fields }" v-if="showrowfilter">
                    <td v-for="field in fields" :key="field.key" class="filter-cell">
                        <b-form-input v-bind:disabled="field.label == 'CMD' ? true : false" class="filter_input form-control-sm" :placeholder="field.label" @keyup ="FilterChange($event.target.value, field.key)"></b-form-input>
                    </td>
                </template>

                <!-- Data Row -->
                <template v-slot:cell()="data">
                    <div :class="UpdateCellColor(data.item)">
                        <span v-if="data.field.formatter == 'NumComma'" class="pull-right">{{ data.value }}</span>
                        <span v-else-if="data.field.formatter == 'NumCurrency'" class="pull-right">{{ data.value }}</span>
                        <span v-else-if="data.field.label == 'CMD'" class="col-cmd">
                            <base-button type="secondary" @click="EditData(data.item)"><i class="fa fa-pen"></i> Edit</base-button>
                            <base-button type="danger" @click="DeleteData(data.item)"><i class="fa fa-trash"></i> Delete</base-button>
                        </span>
                        <span v-else-if="data.field.key == 'RINDEX'">
                            {{data.index + 1}}
                        </span>
                        <span v-else>{{ data.value }}</span>
                    </div>
                </template>

                <!-- Busy -->
                <template v-slot:table-busy>
                    <div class="text-center text-dark">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                
            </b-table>    

            <b-row class="row-pagination" v-if="showPagination == true">
                <b-col lg="4"><label class="text-gray pl-2 pt-2">Total Rows: {{NumComma(totalRows)}}</label></b-col>
                <b-col></b-col>
                <b-col lg="4">
                    <base-pagination class="float-right" size="sm" :page-count="totalPage" v-model="currentPage"></base-pagination>
                    <!-- <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        pills 
                    >
                    </b-pagination> -->
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import EventBus from "../Event-Bus.vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    props:{
      datas:Array,
      cols:Array,
      showscoll:Boolean,
      header_nowarp:Boolean,
      showfilter:Boolean,
      rowdbclick_enable:Boolean,
      rowdbclick_emit:Boolean,
      HeaderStick:String,
      perPage:Number,
      isBusy:Boolean,
      showPagination:Boolean,
      GridHeight:String,
      ShowSort:Boolean,
      EmitFilter:Boolean,
      CustomCellColor:Array,
      CustomCellKey:String
    },
    data() {
      return {
        fields:[],
        items:[],
        allitems:[],
        responsive:false,
        showrowfilter:false,
        filter: null,
        filterOn: [],
        totalRows: 1,
        currentPage: 1,
        arrFilter:[],
        Filtering:false,
        GridSmall:true,
        GridBorderless:true,
        totalPage: 1
      }
    },
    methods: {
        NumComma(val){
            if(val !== undefined && val !== null)
                return val.toLocaleString();
        },
        NumCurrency(val){
            if(val !== undefined && val !== null)
                return val.toLocaleString(undefined, {minimumFractionDigits: 2});
        },
        EditData(item){
            this.$parent.GridEditData(item);
        },
        DeleteData(item){
            this.$parent.GridDeleteData(item);
        },
        RowDBClick(item,index){
            if(this.rowdbclick_enable == true)
            {
                if(this.rowdbclick_emit == true){
                    EventBus.$emit('GridRowDBClick', item);
                }
                else{
                    this.$parent.GridRowDBClick(item,index);
                    
                }
                console.log(item);
            }   
        },
        FilterChange(Value, Field){            
            if(this.arrFilter.length == 0 || this.arrFilter == null){
                this.arrFilter = [];
                var FilterItem = { key: Field, filter: Value};
                this.arrFilter.push(FilterItem);
            }
            else{
                let IsFound = false;
                for(var i = this.arrFilter.length-1; i >=0; i--){
                    var _FilterItem = this.arrFilter[i];
                    if(_FilterItem.key == Field){
                        if(Value != null && Value != ""){
                            _FilterItem.filter = Value;
                            IsFound = true;
                        }
                        else{
                            this.arrFilter.splice(i,1);
                            IsFound = true;
                            break;
                        }
                    }
                }

                if(IsFound == false){
                    var NewFilterItem = { key: Field, filter: Value};
                    this.arrFilter.push(NewFilterItem);
                }
            }

            if(this.EmitFilter == true)
                EventBus.$emit('GridFilterChange', this.arrFilter);
            else
                this.LocalFilter();                      
        },
        AddSortAble(){
            this.fields.forEach(f => {
                f.sortable = this.ShowSort;
            });
        },
        GetFieldIDX(_field){
            let IDX = 0;
            if(this.allitems != undefined){
                let datacols = Object.keys(this.allitems[0]);
                for(var i = 0; i < datacols.length; i++){
                    var _c = datacols[i];
                    if(_c == _field){
                        IDX = i;
                        // window.console.log("I:"+IDX+" K:"+_c.key);
                        break;
                    }
                }
            }
            
            return IDX;
        },
        getObjVal(obj, n){
            return obj[Object.keys(obj)[n]];
        },
        LocalFilter(){
            if(this.allitems == undefined)
                return;

            if(this.EmitFilter != true){                
                this.Filtering = true;
                if(this.arrFilter.length == 0 || this.arrFilter == null){
                    if(this.allitems.length > 10000)
                        this.items = this.allitems.slice(0, 10000);
                    else
                        this.items = this.allitems;
                }
                else{
                    let filteredRow = this.allitems;
                    let FilterLen = this.arrFilter.length;
                    for(let i = 0; i < FilterLen; i++){
                        let _f = this.arrFilter[i];
                        filteredRow = this.DynamicFilter(filteredRow, _f.key, _f.filter);
                    }
                    this.items = filteredRow;
                }
                // this.items = this.allitems.filter(x => x.Name.includes("ช"));
                // window.console.log(this.items);
                // else{
                //     // let filteredRow = this.allitems;
                //     let filteredRow = this.items;
                //     let FilterLen = this.arrFilter.length;
                //     // for(let i = 0; i < this.arrFilter.length; i++){
                //     for(let i = 0; i < FilterLen; i++){
                //         let _f = this.arrFilter[i];
                //         let IDX = this.GetFieldIDX(_f.key);                    
                //         for(var x = filteredRow.length-1; x >= 0 ; x--){
                //             var r = filteredRow[x];
                //             var old_val = this.getObjVal(r, IDX);
                //             window.console.log("I:"+IDX+" C:"+old_val+" V:"+_f.filter);
                //             if(!String(old_val).includes(String(_f.filter))){
                //                 filteredRow = filteredRow.filter(function(e){
                //                     return e != r;
                //                 });
                //             }
                //         }
                //     }
                //     this.items = filteredRow;
                // }

                this.totalRows = this.items.length;
                this.Filtering = false;                
            }
        },
        ShowWait(_title, _text, _type) {
            this.$notify({
                group: "Waiting",
                title: _title,
                text: _text,
                position: "top right",
                type: _type,
                duration: 3600000
            });
        },
        DynamicFilter(array, key, value) {
            return array.filter(function(e) {
                return String(e[key]).includes(String(value));
                // return e[key] == value;
            });
        },
        UpdateCellColor(VKey){
            if(this.CustomCellColor != null && this.CustomCellColor.length > 0){
                let CellKey = this.CustomCellKey;
                let OP = this.CustomCellColor.filter(x => x[CellKey] == VKey[CellKey]);
                if(OP.length > 0){
                    return OP[0].Class;
                }
            }
        }
    },
    mounted(){
        if(this.header_nowarp == true && this.cols != null){
            this.cols.forEach(x => {
                x.thClass = "text-nowrap";
            });
        }

        this.allitems = this.data;
        if(this.allitems !== undefined){
            if(this.allitems.length > 10000){
                this.items = this.allitems.slice(0, 10001);
            }
            else{
                this.items = this.data;
            }
        }

        this.fields = this.cols;
        if(this.showscoll == true)
            this.responsive = this.GridHeight;

        this.showrowfilter = this.showfilter;

        this.totalRows = this.items.length;

        if(this.ShowSort != null)
            this.AddSortAble();

        // window.console.log(this.CustomCellColor);
    },
    watch:{
        datas: function(New){
            this.allitems = New;
            if(this.allitems !== undefined){
                if(this.allitems.length > 10000){
                    this.items = this.allitems.slice(0, 10001);
                }
                else{
                    this.items = this.allitems;
                }
            }

            // this.items = New;
            this.totalRows = this.items.length;
        },
        Filtering(N){
            if(N == true){
                this.ShowWait("Data Filter","Filtering...","info");
            }
            else{
                this.$notify({ group: "Waiting", clean: true });
            }
        },
        totalRows(N){
            this.totalPage = (N < this.perPage) ? 1 : (N / this.perPage);
        }
    }
}
</script>

<style scoped>
.filter_input{
    padding: 5px !important;
}

.filter-cell{
    padding: 2px !important;
    /* padding-right: 2px !important; */
}

.row-pagination{
    margin-top: -10px;
}

table tr td span{
    white-space: nowrap;
}
</style>