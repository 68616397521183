<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    VocalGets(){
        return axios.get(Vue.prototype.$bkURL + "vocals");
    },
    VocalAdd(vc){
        return axios.post(Vue.prototype.$bkURL + "vocal", vc);
    },
    VocalUpdate(vc){
        return axios.patch(Vue.prototype.$bkURL + "vocal", vc);
    },
    VocalDelete(vc_id){
        return axios.delete(Vue.prototype.$bkURL + "vocal?id="+vc_id);
    }
}
</script>