<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar :logo="Logo" :autoClose="true" title="ThaiCMA" shortTitle="SV">
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Site',
            icon: 'ni ni-planet text-danger',
            path: '/sitemanage'
          }"
        ></sidebar-item>   

        <sidebar-item
          :link="{
            name: 'News',
            icon: 'ni ni-collection',
            path: '/newsmanage'
          }"
        ></sidebar-item> 

        <sidebar-item
          :link="{
            name: 'Traning&Seminar',
            icon: 'ni ni-books text-info',
            path: '/seminarmanage'
          }"
        ></sidebar-item>        

        <sidebar-item
          :link="{
            name: 'CMA/Angel Choir',
            icon: 'ni ni-paper-diploma text-warning',
            path: '/choirministrymanage'
          }"
        ></sidebar-item> 

        <sidebar-item
          :link="{
            name: 'Members',
            icon: 'ni ni-single-02 text-success',
          }"
        >
          <sidebar-item :link="{ name: 'Member', path: '/membermanage' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Church', path: '/churchmanage' }"></sidebar-item>
          <sidebar-item :link="{ name: 'MemberType', path: '/mbtypemanage' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Responsibilty', path: '/rbchmanage' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Vocal', path: '/vocalmanage' }"></sidebar-item>          
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'User',
            icon: 'ni ni-circle-08 text-dark',
          }"
        >
          <sidebar-item :link="{ name: 'SignOut', path: '/Login' }"></sidebar-item>
        </sidebar-item>

      </template>
    </side-bar>

    <div class="main-content">
      <!-- <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar> -->

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>

  </div>
</template>
<script>
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { FadeTransition } from "vue2-transitions";
// import UserService from '../services/UserService.vue'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    FadeTransition,
  },
  data(){
    return{
      CUser:{},
      TankSelect:null,
      TankLists:[],
      Menus:[],
      Logo:require("../assets/img/sidebar-logo.png")
    }
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    GetMenuList(){
      // UserService.GetMenuList(this.CUser)
      //               .then(res => {
      //                 if(res.data.STATUS != "COMPLETE"){
      //                   this.$swal({
      //                             title:'Get Menu!!!',
      //                             html:"System can't get menu.",
      //                             icon:'error'
      //                           });
      //                   return;
      //                 }
      //                 this.Menus = res.data.DATA;
      //               })
      //               .catch(err => {
      //                 this.$swal({
      //                             title:'Get Menu!!!',
      //                             html:err,
      //                             icon:'error'
      //                           });
      //               });
    },
    CheckEnableMenu(mname){
      // console.log(mname);
      let found = false;
      if(this.Menus.length > 0){
        for(var i = 0; i < this.Menus.length; i++) {
          var MN = this.Menus[i];
          if(MN.RoleName == mname){
            // console.log(mname +":"+MN.Grant);
            return MN.Grant;
          }
        }
      }
      
      return found;
    },
  },
  mounted() {
    this.initScrollbar();

    this.CUser = this.$session.get('CUser');
    this.TankLists = this.$session.get('TKList');

    //Auto Collapse SideBar
    let docClasses = document.body.classList
    docClasses.add('g-sidenav-hidden')
    docClasses.remove('g-sidenav-show')
    docClasses.remove('g-sidenav-pinned')
    this.$sidebar.isMinimized = true

    this.GetMenuList();
  },
};
</script>

<style>
</style>
