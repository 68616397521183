<template>
    <div>
        <!-- Main Content -->
        <div class="container-fluid">
            <!-- Header Menu -->
            <div class="row">
                <div class="col-sm-12">
                    <h6 class="h2 d-inline-block text-dark mt-2 mb-0">
                        <i :class="'ni '+PageIcon+' mr-1'"></i>{{PageTitle}}
                    </h6>

                    <base-button class="ml-2 mb-3" size="sm" type="info" @click="NewItem()">
                        <i class="fa fa-plus-circle"></i>{{MenuNewLabel}}
                    </base-button>
                </div>
            </div>

            <!-- Table Data -->
            <div class="row mt-2">
                <div class="col-sm-3" v-for="(tc,index) in Items" :key="index">
                    <tc-card
                        v-bind:Name="tc.name"
                        v-bind:Surname="tc.surname"
                        v-bind:Nickname="tc.nickname"
                        v-bind:Church="tc.church"
                        v-bind:Pic="tc.pic"
                        v-bind:IG="tc.ig"
                        v-bind:Facebook="tc.fb"
                        v-bind:MenuEnable="true"
                        v-bind:Item="tc"
                    >

                    </tc-card>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <modal :show="ModalEditOpen" size="lg">
            <div class="container-fluid">
                <h4 class="text-dark mb-1">{{ModalEditTitle}} Add/Edit</h4>

                <div class="row">
                    <div class="col-sm-4 text-center">
                        <label class="form-control-label">Pic</label>
                        <img :src="EditItem.pic != '' && EditItem.pic != null ? GetFullImageUrl(EditItem.pic) : NoImagePath" class="img w-100"/>
                        <input type="file" @change="PreviewFileChange" class="form-control" accept="image/png, image/jpeg">
                    </div>
                    <div class="col-sm-8">
                        <div class="row">
                            <label class="col-sm-3 col-form-label form-control-label pull-right">Name</label>
                            <div class="col-sm-9">
                                <base-input v-model="EditItem.name"></base-input>
                            </div>
                        </div>
                        
                        <div class="row">
                            <label class="col-sm-3 col-form-label form-control-label pull-right">Surname</label>
                            <div class="col-sm-9">
                                <base-input v-model="EditItem.surname"></base-input>
                            </div>
                        </div>

                        <div class="row">
                            <label class="col-sm-3 col-form-label form-control-label pull-right">Nickname</label>
                            <div class="col-sm-9">
                                <base-input v-model="EditItem.nickname"></base-input>
                            </div>
                        </div>

                       <div class="row">
                            <label class="col-sm-3 col-form-label form-control-label pull-right">Church</label>
                            <div class="col-sm-9">
                                <base-input>
                                    <select class="form-control" v-model="EditItem.church_id">
                                        <option v-for="(op, index) in optChurch" :key="index"
                                            v-bind:value="op.value"
                                        >
                                            {{op.text}}
                                        </option>
                                    </select>
                                </base-input>
                            </div>
                       </div>

                        <div class="row">
                            <label class="col-sm-3 col-form-label form-control-label pull-right">Birthdate</label>
                            <div class="col-sm-9">
                                <base-input type="date" v-model="EditItem.birthdate"></base-input>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="fab fa-facebook mt-3 mr-1  text-center fa-lg"></span>
                                    </div>
                                    <input class="form-control" placeholder="Facebook" v-model="EditItem.fb">
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="fab fa-instagram mt-3 mr-1 text-center fa-lg"></span>
                                    </div>
                                    <input class="form-control" placeholder="Instragram" v-model="EditItem.ig">
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>


                <div class="row mt-1 justify-content-end">
                    <base-button @click="SaveItem()" type="success"><i class="fa fa-save"></i> Save</base-button>
                    <base-button @click="CancelSaveItem()" type="dark"><i class="fa fa-times"></i> Close</base-button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import BaseButton from '../components/BaseButton.vue';
import TeacherService from '../services/TeacherService.vue';
import TeacherCard from '../component_thaicma/TeacherCard.vue';
import EventBus from '../Event-Bus.vue';
import FoundationService from "../services/FoundationService.vue";

export default {
    components: {
        BaseButton,
        "tc-card":TeacherCard
    },
    data(){
        return{
            PageTitle: "Teacher Manage",
            PageIcon: "ni-paper-diploma text-warning",            
            MenuNewLabel: "New Teacher",
            Items:[],
            EditItem:{
                name: "",
                surname: "",
                nickname: "",
                church_id: null,
                birthdate: null,
                pic: null,
                fb: "",
                ig: "",
                idx: 1
            },
            KeyItem: "tcid",
            ModalEditTitle: "Teacher",
            ModalEditOpen: false,
            SubKeys:[
                "name",
                "surname"
            ],
            NoImagePath: require("@/assets/img/image_not_available.png"),
            optChurch:[
                { value:null, text: "--Select Church--" }
            ],
        }
    },
    methods:{
        GetItemLists(){
            TeacherService.TeacherGets()
                .then(res => {
                    this.Items = [];
                    this.Items = res.data;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        NewItem(){
            this.EditItem ={
                name: "",
                surname: "",
                nickname: "",
                church_id: null,
                birthdate: null,
                pic: null,
                fb: "",
                ig: "",
                idx: 1
            };
            if(this.Items.length > 0){
                let LastIdx = Math.max.apply(Math, this.Items.map(function(f) { return f.idx; }));
                this.EditItem.idx = LastIdx + 1;
            }
            this.ModalEditOpen = true;
        },
        ValidateData(){
            let IsOK = false;
            let Notice = "";

            if(this.EditItem.name == "")
                Notice += "Please insert <b class='text-danger'>Name</b><br/>";
            
            if(this.EditItem.surname == "")
                Notice += "Please insert <b class='text-danger'>Surname</b><br/>";

            if(this.EditItem.idx <= 0)
                Notice += "Index must greater than <b class='text-danger'>Zero</b>"

            if(this.EditItem.church_id == null)
                Notice += "Please insert <b class='text-danger'>Church</b><br/>"         
            
            if(Notice != ""){
                this.$swal({
                    title: this.ModalEditTitle +" invalid data!!!",
                    html: Notice,
                    icon: "warning"
                })
                IsOK = false;
            }
            else{
                IsOK = true;
            }

            return IsOK;
        },
        SaveItem(){
            let IsOK = this.ValidateData();

            if(IsOK == true){
                if(this.EditItem[this.KeyItem] == null || this.EditItem[this.KeyItem] == ""){
                    TeacherService.TeacherAdd(this.EditItem)
                        .then(res => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.ModalEditOpen = false;
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
                else{
                    TeacherService.TeacherUpdate(this.EditItem)
                        .then(res => {
                            this.$swal({
                                title: "Update "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.ModalEditOpen = false;
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Update "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
            }
        },
        CancelSaveItem(){
            this.ModalEditOpen = false;
        },
        DeleteItem(ItemKey){
            TeacherService.TeacherDelete(ItemKey)
                        .then(res => {
                            this.$swal({
                                title: "Delete "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Delete "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
        },
        DateToInput(dt){
            let result = null

            try{
                let Temp = new Date(dt);
                // yyyy-MM-dd
                let year = Temp.getFullYear();
                let month = ('0' + (Temp.getMonth() + 1)).slice(-2);
                let date = ('0' + (Temp.getDate())).slice(-2);

                result = year+"-"+month+"-"+date;
                // console.log(result);
            }
            catch{
                result = null;
            }
            
            return result;
        },
        GetFullImageUrl(img_url){
           return this.$bkURL +"imgs/"+img_url;
        },
        PreviewFileChange(ev){
            console.log(ev);
            let UpFile = ev.target.files[0];
            if(this.EditItem.tcid == null){
                let IsOK = this.ValidateData();
                if(IsOK == true){
                    TeacherService.TeacherAdd(this.EditItem)
                        .then(res => {            
                            console.log(res.data);                                            
                            TeacherService.TeacherGetbyName(this.EditItem.name, this.EditItem.surname)
                                .then(res2 => {
                                    // console.log(res2.data);
                                    let SavedItem = res2.data;
                                    this.EditItem = SavedItem;
                                    this.EditItem.birthdate = this.DateTimeToInput(SavedItem.birthdate);
                                    this.DoUploadFile(UpFile);
                                })
                                .catch(err2 => {
                                    this.$swal({
                                        title: "Save "+this.ModalEditTitle+" Error!!!",
                                        html: err2,
                                        icon: "error"
                                    });
                                });
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
            }
            else{
                this.DoUploadFile(UpFile);
            }            
        },
        DoUploadFile(UpFile){
            let formData  = new FormData();
            formData.append('file', UpFile);
            formData.append('tcid', this.EditItem.tcid);
            TeacherService.TeacherPicAdd(formData)
                .then(res => {
                    this.EditItem.pic = res.data;
                })
                .catch(err => {
                    this.$swal({
                        title: "Save Preview Image Error!!!",
                        html: err,
                        icon: "error"
                    })
                });
        },
        GetChurchList(){
            let firstOpt = { value:null, text: "--Select Church--" };
            this.optChurch = [];
            this.optChurch.push(firstOpt);
            FoundationService.ChurchGets()
                .then(res => {
                    let FDs = res.data;
                    let opt = this.ListToOpt(FDs, "church_id", "churchname", firstOpt);
                    this.optChurch = opt;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        ListToOpt(IList, FieldValue, FieldText, firstOpt){
            let result = [];
            if(firstOpt != null)
                result.push(firstOpt);

            for(let i = 0; i < IList.length; i++){
                let IT = IList[i];
                let opt = {
                    value: (FieldValue == null? IT : IT[FieldValue]),
                    text: IT[FieldText]
                };
                result.push(opt);
            }
            return result;
        }
    },
    mounted(){
        this.GetItemLists();
        this.GetChurchList();

        EventBus.$on("TeacherReqEdit", (EditData) => {
            // console.log(EditData);
            this.EditItem = JSON.parse(JSON.stringify(EditData));
            this.EditItem.birthdate = this.DateToInput(this.EditItem.birthdate);
            this.ModalEditOpen = true;
        });

        EventBus.$on("TeacherReqDelete", (DeleteItem) => {
            // console.log(DeleteItem);
            let ItemKey = DeleteItem[this.KeyItem];
            this.DeleteItem(ItemKey);
        });
    },
    beforeDestroy(){
        EventBus.$off("TeacherReqEdit");
        EventBus.$off("TeacherReqDelete");
    }
    
}
</script>

<style scoped>
@import "~vue2-editor/dist/vue2-editor.css";
</style>