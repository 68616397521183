<template>
    <div>
        <div class="blog-entry">
            <!-- <a href="blog-single.html" class="block-20" style="background-image: url('images/blog1.jpg');">
            </a> -->
            <div class="block-20" :style="'background-image: url('+(Pic != '' && Pic != null ? (imgApiURL + Pic) : imageDefault)+');'"></div>
            <div class="text d-block">
              <div class="meta">
                <p>
                  <a href="#"><span class="fa fa-calendar mr-2"></span>{{DateToString(EventDate)}}</a>
                </p>
              </div>
              <h3 class="heading"><a href="#">{{Name}}</a></h3>
              <p>{{Description}}</p>
              <!-- <p class="mb-0">
                  <a href="#" class="btn-custom d-flex align-items-center justify-content-center">
                  <i class="fa fa-chevron-right"></i><span class="sr-only">Read more</span>
                  </a>
                </p> -->
            </div>

            <div v-if="MenuEnable == true" class="">
                <base-button type="info" class="float-left" @click="itemEdit()"><i class="fa fa-pen"></i> Edit</base-button>
                <base-button type="danger" class="float-right" @click="itemDelete()"><i class="fa fa-trash"></i> Delete</base-button>
            </div>
        </div>

    </div>
</template>

<script>
import BaseButton from '../components/BaseButton.vue'
import EventBus from '../Event-Bus.vue'

export default {
    components: { BaseButton },
    props:{
        Name:String,
        Description:String,
        EventDate:String,
        Pic:String,
        MenuEnable:Boolean,
        Item:Object
    },
    data(){
        return{
            imageDefault: require('../assets/img/image_not_available.png'),
            imgApiURL: this.$bkURL+"imgs/"
        }
    },
    methods:{
        itemEdit(){
            EventBus.$emit("BlogReqEdit", this.Item);
        },
        itemDelete(){
            this.$swal({
                            icon: 'question',
                            title: 'Delete News',
                            text: 'Are you sure to delete '+this.Item.name+'?',
                            showCloseButton: false,
                            showCancelButton: true,
                            focusConfirm: false,
                            confirmButtonText: '<i class="fa fa-trash"></i> Delete',
                            confirmButtonColor: '#dc3545',  
                            cancelButtonText: '<i class="fa fa-times"></i> Cancel',
                            cancelButtonColor: '#6c757d'
                        })
                .then((result) => {
                    if(result.isConfirmed){
                        EventBus.$emit("BlogReqDelete", this.Item);
                    }
                });
        },
        DateToString(IDate){
            let enMonths =  ["January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December" ];

            let result = null

            try{
                let Temp = new Date(IDate);
                
                // yyyy-MM-dd
                let year = Temp.getFullYear();
                let month = enMonths[Temp.getMonth()];
                let date = Temp.getDate();

                result = date + " " + month + " " + year;
                // console.log(result);
            }
            catch{
                result = null;
            }
            
            return result;
        }
    }
}
</script>

<style scoped>

</style>