<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    TeacherGets(){
        return axios.get(Vue.prototype.$bkURL + "teachers");
    },
    TeacherGetbyName(name,surname){
        return axios.get(Vue.prototype.$bkURL + "teacher/name?firstname="+name+"&lastname="+surname);
    },
    TeacherAdd(tc){
        return axios.post(Vue.prototype.$bkURL + "teacher", tc);
    },
    TeacherUpdate(tc){
        return axios.patch(Vue.prototype.$bkURL + "teacher", tc);
    },
    TeacherDelete(tcid){
        return axios.delete(Vue.prototype.$bkURL + "teacher?id="+tcid);
    },
    TeacherPicAdd(fdata){
        return axios.post(Vue.prototype.$bkURL + "teacher/pic", fdata);
    }
}
</script>