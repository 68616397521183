<template>
    <div>
        <!-- Main Content -->
        <div class="container-fluid">
            <!-- Header Menu -->
            <div class="row">
                <div class="col-sm-12">
                    <h6 class="h2 d-inline-block text-dark mt-2 mb-0">
                        <i :class="'ni '+PageIcon+' mr-1'"></i>{{PageTitle}}
                    </h6>

                    <base-button class="ml-2 mb-3" size="sm" type="info" @click="NewItem()">
                        <i class="fa fa-plus-circle"></i>{{MenuNewLabel}}
                    </base-button>
                </div>
            </div>

            <!-- Table Data -->
            <div class="row mt-2">
                <div class="col-sm-12">
                    <datagrid
                        v-bind:showscoll="true"
                        v-bind:header_nowarp="true"
                        v-bind:showfilter="true"
                        v-bind:rowdbclick_enable="false"
                        v-bind:showPagination="true"
                        v-bind:ShowSort="true"
                        v-bind:HeaderStick="TBHeaderStick"
                        v-bind:isBusy="TBBusy"
                        v-bind:perPage="TBRowPerPage"
                        v-bind:datas="Items"
                        v-bind:cols="Fields"
                    />
                </div>
            </div>
        </div>

        <!-- Modal -->
        <modal :show="ModalEditOpen" size="md">
            <div class="container-fluid">
                <h4 class="text-dark mb-1">{{ModalEditTitle}} Add/Edit</h4>

                <div class="form-group row">
                    <label class="col-md-12 col-form-label form-control-label pull-right">Member type</label>
                    <div class="col-md-12">
                        <base-input v-model="EditItem.typename"></base-input>
                    </div>
                </div>

                <base-input label="Description">
                    <textarea class="form-control"  v-model="EditItem.regisfee" rows="2"></textarea>
                </base-input>

                <div class="row">
                    <div class="col-md-6">
                        <base-input label="Regis Price" type="number" v-model.number="EditItem.regis_amount"></base-input>
                    </div>
                    <div class="col-md-6">
                        <base-input label="Renew Price" type="number" v-model.number="EditItem.renew_amount"></base-input>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2 col-form-label form-control-label pull-right">Index</label>
                    <div class="col-md-10">
                        <base-input type="number" v-model.number="EditItem.idx"></base-input>
                    </div>
                </div>

                <div class="row mt-2 justify-content-end">
                    <base-button @click="SaveItem()" type="success"><i class="fa fa-save"></i> Save</base-button>
                    <base-button @click="CancelSaveItem()" type="dark"><i class="fa fa-times"></i> Close</base-button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import BaseButton from '../components/BaseButton.vue';
import datagrid from "../component_thaicma/DataGrid.vue";
import MemberTypeService from "../services/MemberTypeService.vue";

export default {
    components: {
        datagrid,
        BaseButton
    },
    data(){
        return{
            PageTitle: "Member type Manage",
            PageIcon: "ni-single-02 text-success",            
            MenuNewLabel: "New Member type",
            TBHeaderStick: "70vh",
            TBBusy: false,
            TBRowPerPage: 100,
            Items:[],
            Fields:[
                { key: "mbtype_id", label: "CMD" },
                { key: "typename", label: "Type" },
                { key: "regisfee", label: "Description" },
                { key: "regis_amount", label: "New Member Price" },
                { key: "renew_amount", label: "Renew Price" },
                { key: "idx", label: "Index" },
            ],
            EditItem:{
                typename:"",
                regisfee:"",
                regis_amount:0,
                renew_amount:0,
                idx:1
            },
            KeyItem: "mbtype_id",
            ModalEditTitle: "Member type",
            ModalEditOpen: false,
            SubKeys:[
                "typename"
            ]
        }
    },
    methods:{
        GetItemLists(){
            MemberTypeService.MemberTypeGets()
                .then(res => {
                    this.Items = [];
                    this.Items = res.data;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        NewItem(){
            this.EditItem ={
                typename:"",
                regisfee:"",
                regis_amount:0,
                renew_amount:0,
                idx:1
            };
            if(this.Items.length > 0){
                let LastIdx = Math.max.apply(Math, this.Items.map(function(f) { return f.idx; }));
                this.EditItem.idx = LastIdx + 1;
            }
            this.ModalEditOpen = true;
        },
        ValidateData(){
            let IsOK = false;
            let Notice = "";

            if(this.EditItem.typename == "")
                Notice += "Please insert <b class='text-danger'>Member type</b><br/>";
            
            if(this.EditItem.regisfee == "")
                Notice += "Please insert <b class='text-danger'>Description</b><br/>";

            if(this.EditItem.idx <= 0)
                Notice += "Index must greater than <b class='text-danger'>Zero</b>"

            if(Notice != ""){
                this.$swal({
                    title: this.ModalEditTitle +" invalid data!!!",
                    html: Notice,
                    icon: "warning"
                })
                IsOK = false;
            }
            else{
                IsOK = true;
            }

            return IsOK;
        },
        SaveItem(){
            let IsOK = this.ValidateData();

            if(IsOK == true){
                if(this.EditItem[this.KeyItem] == null || this.EditItem[this.KeyItem] == ""){
                    MemberTypeService.MemberTypeAdd(this.EditItem)
                        .then(res => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.ModalEditOpen = false;
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
                else{
                    MemberTypeService.MemberTypeUpdate(this.EditItem)
                        .then(res => {
                            this.$swal({
                                title: "Update "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.ModalEditOpen = false;
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Update "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
            }
        },
        CancelSaveItem(){
            this.ModalEditOpen = false;
        },
        DeleteItem(ItemKey){
            MemberTypeService.MemberTypeDelete(ItemKey)
                        .then(res => {
                            this.$swal({
                                title: "Delete "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Delete "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
        },
        GridEditData(EditData){
            this.EditItem = JSON.parse(JSON.stringify(EditData));
            this.ModalEditOpen = true;
        },
        GridDeleteData(DeleteItem){
            let subdata = "";
            for(let i = 0; i < this.SubKeys.length; i++){
                let sk = this.SubKeys[i];
                subdata += " "+DeleteItem[sk];
            }

            this.$swal({
                title: this.ModalEditTitle + ' Delete',
                html: '<h5>Are you sure to delete<b class="text-danger">'+subdata+'</b>?</h5>',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                cancelButtonColor: '#000',
                confirmButtonText: '<i class="fa fa-trash"> Delete</i>'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let ItemKey = DeleteItem[this.KeyItem];
                    this.DeleteItem(ItemKey);
                }
            });
        }
    },
    mounted(){
        this.GetItemLists();
    }
}
</script>

<style scoped>

</style>