<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    UserLogin(US,PW){
        let UData = {
            user: US,
            pw: PW
        };
        return axios.post(Vue.prototype.$bkURL + "staff/login", UData);
    },
}
</script>