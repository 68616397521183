<template>
    <div>
        <!-- Main Content -->
        <div class="container-fluid">
            <!-- Header Menu -->
            <div class="row">
                <div class="col-sm-12">
                    <h6 class="h2 d-inline-block text-dark mt-2 mb-0">
                        <i :class="'ni '+PageIcon+' mr-1'"></i>{{PageTitle}}
                    </h6>

                    <base-button class="ml-2 mb-3" size="sm" type="info" @click="NewItem()">
                        <i class="fa fa-plus-circle"></i>{{MenuNewLabel}}
                    </base-button>
                </div>

                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group row">
                                <label class="col-md-2 col-form-label form-control-label">Type</label>
                                <div class="col-md-10">
                                    <select class="form-control" v-model="FocusType">
                                        <option v-for="(op, idt) in optType" :key="idt"
                                            v-bind:value="op.value"
                                        >
                                            {{op.text}}
                                        </option>
                                    </select>
                                </div>
                            </div>          
                        </div>
                    </div>
                    <!-- <label class="form-control-label col-sm-12">Type</label> -->
                    
                </div>
            </div>

            <!-- Table Data -->
            <div class="row mt-2">
                <div class="col-sm-3" v-for="(cl,index) in ShowItems" :key="index">
                    <cl-card
                        v-bind:Name="cl.name"
                        v-bind:Banner="cl.pic"
                        v-bind:MenuEnable="true"
                        v-bind:Item="cl"
                    >

                    </cl-card>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <modal :show="ModalEditOpen" size="lg" id="modal-edit">
            <div class="container-fluid">
                <h4 class="text-dark mb-1">{{ModalEditTitle}} Add/Edit</h4>

                <div class="row">
                    <div class="col-sm-4 text-center">
                        <label class="form-control-label">Preview Pic</label>
                        <img :src="EditItem.pic != '' && EditItem.pic != null ? GetFullImageUrl(EditItem.pic) : NoImagePath" class="img w-100"/>
                        <input type="file" @change="PreviewFileChange" class="form-control" accept="image/png, image/jpeg">

                        <base-button class="mt-3" v-if="EditItem.fulldescmode == 'local'" block type="info" @click="ShowEditImageGallery()"><i class="fas fa-image"></i> Gallery Edit</base-button>
                    </div>
                    <div class="col-sm-8">
                        <div class="row">
                            <label class="col-sm-2 col-form-label form-control-label pull-right">Name</label>
                            <div class="col-sm-10">
                                <base-input v-model="EditItem.name"></base-input>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="row">
                                    <label class="col-sm-12 form-control-label">StartDate</label>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <base-input type="datetime-local" v-model="EditItem.startdate" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="row">
                                    <label class="col-sm-12 form-control-label">EndDate</label>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <base-input type="datetime-local" v-model="EditItem.enddate" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <label class="form-control-label col-sm-12">Password</label>
                            <div class="col-sm-8">
                                <base-input type="password" :disabled="!EditItem.key_require" v-model="EditItem.access_key" />                               
                            </div>
                            <div class="col-sm-4">
                                <base-checkbox class="mb-3" v-model="EditItem.key_require">
                                    Enable PWD
                                </base-checkbox>
                            </div>
                        </div>

                        <div class="row">
                            <label class="form-control-label col-sm-12">Category</label>
                            <div class="col-sm-8">
                                <base-input v-if="EnableNewCategory == false && optCategory.length > 1">
                                    <select class="form-control" v-model="EditItem.category">
                                        <option v-for="(op, index) in optCategory" :key="index"
                                            v-bind:value="op.value"
                                        >
                                            {{op.text}}
                                        </option>
                                    </select>
                                </base-input>
                                <base-input v-else placeholder="Category" v-model="NewCategory"></base-input>                                
                            </div>
                            <div class="col-sm-4">
                                <base-checkbox class="mb-3" v-model="EnableNewCategory">
                                    New Category
                                </base-checkbox>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-sm-7">
                                <label class="form-control-label col-sm-12">Content Mode</label>
                                <b-form-group class="ml-4">
                                    <b-form-radio v-model="EditItem.fulldescmode" name="rdo-contentmode" value="external">External URL</b-form-radio>
                                    <b-form-radio v-model="EditItem.fulldescmode" name="rdo-contentmode" value="local">Local Content</b-form-radio>
                                </b-form-group>
                            </div>

                            <div class="col-sm-5">
                                <base-input label="Index" type="number" v-model.number="EditItem.idx" />
                            </div>
                        </div>

                        
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h5 class="form-control-label text-gray">
                            {{EditItem.fulldescmode == 'external' ? "Content Link" : "Local Content"}}

                            <base-button v-if="EditItem.fulldescmode != 'external'" size="sm" class="float-right" @click="ShowPreviewLocalContent = !ShowPreviewLocalContent">
                                {{ShowPreviewLocalContent? 'Hide' : 'Show'}} Preview
                            </base-button>
                        </h5>                        
                    </div>
                </div>

                <div class="row" v-if="EditItem.fulldescmode != 'local'">
                    <div class="col-sm-12">
                        <div class="form-group row">
                            <div class="col-sm-3">
                                <label class="col-form-label form-control-label float-right">External URL</label>
                            </div>
                            <div class="col-sm-9">
                                <base-input v-model="EditItem.exturl"></base-input>
                            </div>
                        </div>
                    </div>                    
                </div>

                <div class="row mb-1" v-else>
                    <div class="col-sm-12 text-center">
                        <div class="row">
                            <div :class="ShowPreviewLocalContent ? 'col-sm-6' : 'col-sm-12'">
                                <vue-editor v-model="EditItem.htmldesc" :customModules="customModulesForEditor" :editorOptions="editorSettings" />
                            </div>
                            <div v-if="ShowPreviewLocalContent == true" class="col-sm-6">
                                <div v-html="EditItem.htmldesc"></div>
                            </div>
                        </div>
                    </div>                    
                </div>

                <div class="row mt-1 justify-content-end">
                    <base-button @click="SaveItem()" type="success"><i class="fa fa-save"></i> Save</base-button>
                    <base-button @click="CancelSaveItem()" type="dark"><i class="fa fa-times"></i> Close</base-button>
                </div>
            </div>
        </modal>

        <!-- Modal Gallery -->
        <modal :show="ModalEditGallery" size="xl">
            <div class="container-fluid">
                <h4 class="text-dark mb-1">Gallery <base-button class="float-right" @click="CloseGalleryEdit()" type="light"><i class="fa fa-times"></i></base-button></h4>

                <div class="row">
                    <div class="col-sm-3" v-for="(eg, inx) in EditGalleries" :key="inx">
                        <card>
                            <img :src="eg.pic != '' && eg.pic != null ? GetFullImageUrl(eg.pic) : NoImagePath" class="img img-gallery w-90"/>
                            <input v-if="eg.pic == '' || eg.pic == null" type="file" @change="GalleryFileChange" class="form-control mt-1" accept="image/png, image/jpeg">
                            <base-button v-if="eg.pic != '' && eg.pic != null" @click="GalleryDeleteImage(eg.item)" type="danger" size="sm" class="float-right"><i class="fa fa-trash"></i></base-button>
                        </card>
                    </div>                    
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import BaseButton from '../components/BaseButton.vue';
import ChoirMinistryService from '../services/ChoirMinistryService.vue';
import ChoirMinistryCard from '../component_thaicma/ChoirMinistryCard.vue';
import EventBus from '../Event-Bus.vue';
import { VueEditor } from 'vue2-editor';
import ImageResize from "quill-image-resize-vue";
import BaseInput from '../components/Inputs/BaseInput.vue';

export default {
    components: {
        BaseButton,
        "cl-card":ChoirMinistryCard,
        VueEditor,
        BaseInput
    },
    data(){
        return{
            PageTitle: "CMA/Angel Choir Manage",
            PageIcon: "ni-book text-info",            
            MenuNewLabel: "New CMA/Angel Choir",
            Items:[],
            ShowItems:[],
            EditItem:{
                name: "",
                category: "",
                type:"",
                pic: null,
                htmldesc: "",
                startdate: null,
                enddate: null,
                fulldescmode: "external",
                exturl: "",
                idx: 1,
                key_require:false,
                access_key: ""
            },
            NewCategory:"",
            EnableNewCategory:false,
            KeyItem: "cmid",
            ModalEditTitle: "CMA Choir",
            ModalEditOpen: false,
            SubKeys:[
                "name"
            ],
            NoImagePath: require("@/assets/img/image_not_available.png"),
            ShowPreviewLocalContent:false,
            customModulesForEditor: [
                { alias: "imageResize", module: ImageResize }
            ],
            editorSettings: {
                modules: {
                    imageResize: {}
                }
            },
            optCategory:[
                {text:"--Category--", value:""}
            ],
            FocusType:"Adult",
            optType:[
                {text:"--Type--", value:""},
                {text:"CMA Choir", value:"Adult"},
                {text:"Angle Choir", value:"Angle"}
            ],
            ModalEditGallery:false,
            EditGalleries:[
                { cmid: null, item: null, pic: null }
            ],
        }
    },
    methods:{
        GetItemLists(){
            ChoirMinistryService.choirministryGets()
                .then(res => {
                    this.Items = [];
                    this.Items = res.data;
                    this.ShowItems = this.Items;

                    this.optCategory = [ {text:"--Category--", value:""} ];
                    let _Items = this.Items;
                    let lookup = {};
                    for (var item, i = 0; item = _Items[i++];) {
                        var _cate = item.category;

                        if(this.FocusType != "" && item.type != this.FocusType)
                            continue;

                        if (!(_cate in lookup) && _cate != "") {
                            lookup[_cate] = 1;
                            this.optCategory.push( {text:_cate, value:_cate});
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
        NewItem(){
            this.EnableNewCategory = false;
            this.NewCategory = "";
            
            this.EditItem ={
                name: "",
                category: "",
                type:this.FocusType,
                pic: null,
                htmldesc: "",
                startdate: null,
                enddate: null,
                fulldescmode: "",
                exturl: "",
                idx: 1,
                key_require:false,
                access_key: ""
            };
            if(this.Items.length > 0){
                let LastIdx = Math.max.apply(Math, this.Items.map(function(f) { return f.idx; }));
                this.EditItem.idx = LastIdx + 1;
            }

            if(this.optCategory.length <= 1){
                this.EnableNewCategory = true;
            }
            this.ModalEditOpen = true;
        },
        ValidateData(){
            let IsOK = false;
            let Notice = "";

            if(this.EnableNewCategory == true)
                this.EditItem.category = this.NewCategory;

            if(this.EditItem.name == "")
                Notice += "Please insert <b class='text-danger'>Choir Ministry Name</b><br/>";
            
            if(this.EditItem.idx <= 0)
                Notice += "Index must greater than <b class='text-danger'>Zero</b>"

            if(this.EditItem.startdate == null)
                Notice += "Please insert <b class='text-danger'>Start Date</b><br/>"

            if(this.EditItem.enddate == null)
                Notice += "Please insert <b class='text-danger'>End Date</b><br/>"

            if(this.EditItem.category == "")
                Notice += "Please insert <b class='text-danger'>Category</b><br/>";

            if(this.EditItem.fulldescmode == '')
                Notice += "Please insert <b class='text-danger'>Content Mode</b><br/>"            
            
            if(this.EditItem.type == '')
                Notice += "Please select <b class='text-danger'>Type</b><br/>" 

            if(this.EditItem.fulldescmode == "external"){
                if(this.EditItem.exturl == ""){
                    Notice += "Please insert <b class='text-danger'>External URL</b><br/>" 
                }

                if(!this.EditItem.exturl.startsWith('http')){
                    Notice += "External URL invalid!!! plase input like <b class='text-warning'>https://google.com</b> " 
                }
            }

            if(Notice != ""){
                this.$swal({
                    title: this.ModalEditTitle +" invalid data!!!",
                    html: Notice,
                    icon: "warning"
                })
                IsOK = false;
            }
            else{
                IsOK = true;
            }

            return IsOK;
        },
        SaveItem(){
            let IsOK = this.ValidateData();

            if(IsOK == true){
                if(this.EditItem[this.KeyItem] == null || this.EditItem[this.KeyItem] == ""){
                    ChoirMinistryService.choirministryAdd(this.EditItem)
                        .then(res => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.ModalEditOpen = false;
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
                else{
                    ChoirMinistryService.choirministryUpdate(this.EditItem)
                        .then(res => {
                            this.$swal({
                                title: "Update "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.ModalEditOpen = false;
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Update "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
            }
        },
        CancelSaveItem(){
            this.ModalEditOpen = false;
        },
        DeleteItem(ItemKey){
            ChoirMinistryService.choirministryDelete(ItemKey)
                        .then(res => {
                            this.$swal({
                                title: "Delete "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Delete "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
        },
        DateTimeToInput(dt){
            let result = "";
            let Temp = new Date(dt);
            // yyyy-MM-ddThh:mm
            let year = Temp.getFullYear();
            let month = ('0' + (Temp.getMonth() + 1)).slice(-2);
            let date = ('0' + (Temp.getDate())).slice(-2);
            let hour = ('0' + Temp.getHours()).slice(-2);
            let minute = ('0' + Temp.getMinutes()).slice(-2);

            result = year+"-"+month+"-"+date+"T"+hour+":"+minute;
            // console.log(result);
            return result;
        },
        GetFullImageUrl(img_url){
           return this.$bkURL +"imgs/"+img_url;
        },
        PreviewFileChange(ev){
            console.log(ev);
            let UpFile = ev.target.files[0];
            if(this.EditItem.cmid == null){
                let IsOK = this.ValidateData();
                if(IsOK == true){
                    ChoirMinistryService.choirministryAdd(this.EditItem)
                        .then(res => {            
                            console.log(res.data);                                            
                            ChoirMinistryService.choirministryGetbyName(this.EditItem.name)
                                .then(res2 => {
                                    // console.log(res2.data);
                                    let SavedItem = res2.data;
                                    this.EditItem = SavedItem;
                                    this.EditItem.startdate = this.DateTimeToInput(SavedItem.startdate);
                                    this.EditItem.enddate = this.DateTimeToInput(SavedItem.enddate);
                                    this.DoUploadFile(UpFile);
                                })
                                .catch(err2 => {
                                    this.$swal({
                                        title: "Save "+this.ModalEditTitle+" Error!!!",
                                        html: err2,
                                        icon: "error"
                                    });
                                });
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
            }
            else{
                this.DoUploadFile(UpFile);
            }            
        },
        DoUploadFile(UpFile){
            let formData  = new FormData();
            formData.append('file', UpFile);
            formData.append('cmid', this.EditItem.cmid);
            ChoirMinistryService.choirministryBannerAdd(formData)
                .then(res => {
                    this.EditItem.pic = res.data;
                })
                .catch(err => {
                    this.$swal({
                        title: "Save Preview Image Error!!!",
                        html: err,
                        icon: "error"
                    })
                });
        },
        GetImageGallery(){
            this.EditGalleries = [{ cmid: null, item: null, pic: null }];
            ChoirMinistryService.choirministryGalleryGet(this.EditItem.cmid)
                .then(res => {               
                    let GL = res.data;
                    GL.push({ cmid: null, item: null, pic: null }); 
                    this.EditGalleries = GL;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        ShowEditImageGallery(){
            this.ModalEditOpen = false;
            this.GetImageGallery();
            this.ModalEditGallery = true;
        },
        CloseGalleryEdit(){
            this.ModalEditOpen = true;
            this.ModalEditGallery = false;
        },
        GalleryFileChange(ev){
            let UpFile = ev.target.files[0];
            this.DoUploadGallery(UpFile);
        },
        DoUploadGallery(UpFile){
            let formData  = new FormData();
            formData.append('file', UpFile);
            formData.append('cmid', this.EditItem.cmid);
            ChoirMinistryService.choirministryGalleryAdd(formData)
                .then(res => {
                    // this.EditItem.pic = res.data;
                    this.GetImageGallery();
                })
                .catch(err => {
                    this.$swal({
                        title: "Save Preview Image Error!!!",
                        html: err,
                        icon: "error"
                    })
                });
        },
        GalleryDeleteImage(picitem){
            this.$swal({
                        icon: 'question',
                        title: 'Delete Picture',
                        text: 'Are you sure to picture?',
                        showCloseButton: false,
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonText: '<i class="fa fa-trash"></i> Delete',
                        confirmButtonColor: '#dc3545',  
                        cancelButtonText: '<i class="fa fa-times"></i> Cancel',
                        cancelButtonColor: '#6c757d'
                    })
            .then((result) => {
                if(result.isConfirmed){
                    ChoirMinistryService.choirministryGalleryDelete(this.EditItem.cmid, picitem)
                        .then(res => {
                            console.log(res);
                            this.GetImageGallery();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Delete Preview Image Error!!!",
                                html: err,
                                icon: "error"
                            })
                        });
                }
            });
            
        }
    },
    watch:{
        FocusType(n){
            if(this.Items.length > 0)
                this.ShowItems = this.Items.filter(f => f.type == n);
        }
    },
    mounted(){
        this.GetItemLists();
        
        EventBus.$on("ChoirMinistryReqEdit", (EditData) => {
            // console.log(EditData);
            this.EditItem = JSON.parse(JSON.stringify(EditData));
            this.EditItem.startdate = this.DateTimeToInput(this.EditItem.startdate);
            this.EditItem.enddate = this.DateTimeToInput(this.EditItem.enddate);
            if(this.optCategory.length <= 1){
                this.EnableNewCategory = true;
            }
            console.log(this.EditItem.key_require);
            this.EditItem.key_require = (this.EditItem.key_require.data[0] == 1 ? true : false);
            this.ModalEditOpen = true;
        });

        EventBus.$on("ChoirMinistryDelete", (DeleteItem) => {
            // console.log(DeleteItem);
            let ItemKey = DeleteItem[this.KeyItem];
            this.DeleteItem(ItemKey);
        });
    },
    beforeDestroy(){
        EventBus.$off("ChoirMinistryReqEdit");
        EventBus.$off("ChoirMinistryDelete");
    }
    
}
</script>

<style scoped>
@import "~vue2-editor/dist/vue2-editor.css";
.img-gallery{
    width: 100%;
    height: auto;
}

#modal-edit {
    overflow-y:scroll;
}
</style>