<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>

    <!-- Page content -->
    <div class="container mt--9 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <!-- KeyField -->
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <h1>ThaiCMA System</h1>
              </div>

              <div>
                <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                  <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <base-input alternative
                                class="mb-3"
                                name="User"
                                :rules="{required: true}"
                                prepend-icon="ni ni-circle-08"
                                placeholder="User"
                                v-model="model.user">
                    </base-input>

                    <base-input alternative
                                class="mb-3"
                                name="Password"
                                :rules="{required: true, min: 4}"
                                prepend-icon="ni ni-lock-circle-open"
                                type="password"
                                placeholder="Password"
                                v-model="model.password">
                    </base-input>

                    <div class="text-center">
                      <base-button type="info" native-type="submit" class="my-2">เข้าสู่ระบบ</base-button>
                    </div>
                  </form>
                </validation-observer>
              </div>
            </div>

          </div>
          <div class="row mt-3">
            <div class="col-6">
              <!-- <router-link to="/dashboard" class="text-light"><small>ลืมรหัสผ่าน?</small></router-link> -->
            </div>
            <div class="col-6 text-right">
              <!-- <router-link to="/register" class="text-light"><small>สร้างบัญชีใหม่</small></router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StaffService from '../services/StaffService.vue';

export default {
  data() {
    return {
      model: {
        user: '',
        password: '',
        rememberMe: false
      },
      user: {
          id: '',
          token: ''
      }
    };
  },
  methods: {
    onSubmit() {
      this.CheckLogin();
    },
    CheckLogin(){
        this.$swal({
                title: 'User Authentication',
                html:'Please wait... <br></br><div class="loader">Loading...</div>',
                showConfirmButton: false,
            });

        StaffService.UserLogin(this.model.user, this.model.password)
            .then(res => {
                console.log(res);
                this.$swal().close();
                this.user = {
                    id: res.data.username,
                    token: res.data.token
                }
                this.$session.set("UA",this.user);
                this.UpdateBackground(true);
                this.$router.push("/seminarmanage");
            })
            .catch(err => {
                this.$swal({
                    icon: 'error',
                    title: 'User Authentication',
                    text: 'User หรือ Password ไม่ถูกต้อง',
                });
            });
    },
    UpdateBackground(IsClear){
      if(IsClear == true)
        document.body.classList.remove('bg-default');
      else
        document.body.classList.add('bg-default');
    },
  },
  mounted(){
    this.UpdateBackground();
  }
};
</script>

<style scoped>
</style>