/*!

=========================================================
* Vue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import VueSession from "vue-session";
import VueSweetalert2 from 'vue-sweetalert2';
import BootstrapVue from "bootstrap-vue";

// router setup
import router from './routes/router';

//--Extra CSS--
import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/css/thaicma_normal.css';
// import './assets/css/font-awesome.min.css';

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueSession);
Vue.use(VueSweetalert2);
Vue.use(BootstrapVue);

//--API--
import BackendConfig from './BackendConfig.vue'
Vue.prototype.$bkURL = BackendConfig.BackendURL();
// Vue.prototype.$bkURL = "https://api.thaicma.org/";
// Vue.prototype.$bkURL = "http://localhost:4001/";

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router
});
