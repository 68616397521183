<template>
    <div>
        <!-- Main Content -->
        <div class="container-fluid">
            <!-- Header Menu -->
            <div class="row">
                <div class="col-sm-12">
                    <h6 class="h2 d-inline-block text-dark mt-2 mb-0">
                        <i :class="'ni '+PageIcon+' mr-1'"></i>{{PageTitle}}
                    </h6>
                </div>
            </div>
        
            <!-- Config Site -->
            <card>
                <div class="row">
                    <div class="col-sm-6">
                        <h5>
                            Site Language
                            <base-dropdown title-classes="btn btn-default mr-0">
                                <template v-slot:title>
                                    <img class="img-language-mini" :src="require('../assets/img/'+(SelectLanguage == 'en' ? 'united-states.png':'thailand.png'))" /> {{SelectLanguage == 'en' ? 'English' : 'Thai' }}
                                </template>
                                <li @click="SelectLanguageChange('en')">
                                    <a class="dropdown-item" href="#">
                                        <img class="img-language" :src="require('../assets/img/united-states.png')" /> English
                                    </a>
                                </li>
                                <li @click="SelectLanguageChange('th')">
                                    <a class="dropdown-item" href="#">
                                        <img class="img-language" :src="require('../assets/img/thailand.png')" /> Thai
                                    </a>
                                </li>
                            </base-dropdown>

                            <base-checkbox size="lg" class="mt--2 ml-2" v-model="SiteItem.defaultlang">
                                <span><b>Default</b></span>
                            </base-checkbox>  
                        </h5>
                        
                    </div>
                    <div class="col-sm-6">
                        <base-button type="success" class="float-right" @click="SaveSiteContent()"><i class="fa fa-save"></i> Save</base-button>                      
                    </div>
                </div>                

                <!-- Site Name -->
                <div class="row">                    
                    <div class="col-sm-6">
                        <h6 class="form-control-label text-center">Preview</h6>
                        <div class="bg-secondary text-dark pt-4 pb-3 pl-2">
                            <span class="subheading">{{SelectLanguage == 'en' ? 'Welcome to' : 'ยินดีต้อนรับเข้าสู่'}} {{SiteItem.shortname}}</span>
                            <h1 class="mb-4">{{SiteItem.name}}</h1>
                            <p class="mb-4">{{SiteItem.welcometext}}</p>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="row">
                            <label class="form-control-label col-sm-12">Name</label>
                            <div class="col-sm-12">
                                <base-input v-model="SiteItem.name"></base-input>
                            </div>
                        </div>
                        <div class="row">
                            <label class="form-control-label col-sm-12">Shortname</label>
                            <div class="col-sm-12">
                                <base-input v-model="SiteItem.shortname"></base-input>
                            </div>
                        </div>
                        <div class="row">
                            <label class="form-control-label col-sm-12">Welcome Text</label>
                            <div class="col-sm-12">
                                <base-input v-model="SiteItem.welcometext"></base-input>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Cover and Contact -->
                <div class="row">
                    <div class="col-sm-7">
                        <h6 class="form-control-label text-center">Cover Image</h6>
                        <img :src="SiteItem.homebanner != '' && SiteItem.homebanner != null ? GetFullImageUrl(SiteItem.homebanner) : NoImagePath" class="img img-cover w-90"/>
                        <input type="file" @change="PreviewFileChange" class="form-control mt-1" accept="image/png, image/jpeg">
                    </div>

                    <div class="col-sm-5">
                        <div class="row">
                            <label class="form-control-label col-sm-3">Address</label>
                            <div class="col-sm-9">
                                <textarea class="form-control"  v-model="SiteItem.addr" rows="2"></textarea>
                            </div>

                            <label class="form-control-label col-sm-3 mt-2">Phone</label>
                            <div class="col-sm-9 mt-2">
                                <base-input v-model="SiteItem.phone"></base-input>
                            </div>

                            <label class="form-control-label col-sm-3">Email</label>
                            <div class="col-sm-9">
                                <base-input v-model="SiteItem.email"></base-input>
                            </div>

                            <label class="form-control-label col-sm-3">Facebook</label>
                            <div class="col-sm-9">
                                <base-input v-model="SiteItem.facebookurl"></base-input>
                            </div>

                            <label class="form-control-label col-sm-3">LineID</label>
                            <div class="col-sm-9">
                                <base-input v-model="SiteItem.lineid"></base-input>
                            </div>
                            
                            <label class="form-control-label col-sm-3">LineURL</label>
                            <div class="col-sm-9">
                                <base-input v-model="SiteItem.lineurl"></base-input>
                            </div>

                            <label class="form-control-label col-sm-3">YoutubeURL</label>
                            <div class="col-sm-9">
                                <base-input v-model="SiteItem.youtubeurl"></base-input>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- About -->
                <div class="row">
                    <div class="col-sm-12 mb-1">
                        <h4 class="text-default text-center">
                            About
                            <!-- <base-button size="md" class="float-right" @click="ShowPreviewContent = !ShowPreviewContent">
                                {{ShowPreviewContent? 'Hide' : 'Show'}} Preview
                            </base-button> -->
                        </h4> 
                    </div>
                    <div class="col-sm-12">
                        <div class="row">
                            <div :class="ShowPreviewContent ? 'col-sm-6' : 'col-sm-12'">
                                <vue-editor v-model="SiteItem.about_html" :customModules="customModulesForEditor" :editorOptions="editorSettings" />
                            </div>
                            <!-- <div v-if="ShowPreviewContent == true" class="col-sm-6">
                                <div v-html="SiteItem.about_html"></div>
                            </div> -->
                        </div>
                    </div>
                </div>

            </card>
        </div>
    </div>
</template>

<script>
import Card from '../components/Cards/Card.vue'
import BaseInput from '../components/Inputs/BaseInput.vue';
import { VueEditor } from 'vue2-editor';
import ImageResize from "quill-image-resize-vue";
import BaseButton from '../components/BaseButton.vue';
import SiteContentService from '../services/SiteContentService.vue';

export default {
    components: {
        Card,
        BaseInput,
        VueEditor,
        BaseButton
    },
    data(){
        return{
            PageTitle: "Site Manage",
            PageIcon: "ni ni-planet text-danger",
            SelectLanguage:"en",
            SiteItem:{
                lang:"en",
                name:"",
                shortname:"",
                welcometext:"",
                homebanner:"",
                addr:"",
                phone:"",
                email:"",
                facebookurl:"",
                lineid:"",
                defaultlang:false,
                logo:"",
                about_html: "<h3>Please say something.</h3>"
            },
            ShowPreviewContent:false,
            customModulesForEditor: [
                { alias: "imageResize", module: ImageResize }
            ],
            editorSettings: {
                modules: {
                    imageResize: {}
                }
            },
            NoImagePath: require("@/assets/img/image_not_available.png")
        }
    },
    methods:{
        SelectLanguageChange(lang){
            // console.log(lang);
            this.SelectLanguage = lang;
            this.GetSiteContent();
        },
        GetSiteContent(){
            SiteContentService.SiteContentGet(this.SelectLanguage)
                .then(res => {
                    this.SiteItem = res.data;
                    this.SiteItem.defaultlang = (this.SiteItem.defaultlang.data[0] == 1 ? true : false);
                })
                .catch(err => {
                    console.log(err);
                })
        },
        SaveSiteContent(){
            SiteContentService.SiteContentUpdate(this.SiteItem)
                .then(res => {
                    this.$swal({
                        title:"Site Setting",
                        html:res.data,
                        icon:"success"
                    });
                })
                .catch(err => {
                    this.$swal({
                        title:"Site Setting",
                        html:err,
                        icon:"error"
                    });
                });
            
            if(this.SiteItem.defaultlang == true){
                SiteContentService.SiteContentDefalutLang(this.SiteItem.lang)
                    .then(res => {
                        console.log(res.data);
                        // this.$swal({
                        //     title:"Site default Language",
                        //     html:res.data,
                        //     icon:"success"
                        // });
                    })
                    .catch(err => {
                        this.$swal({
                            title:"Site default Language",
                            html:err,
                            icon:"error"
                        });
                    });
            }
        },
        PreviewFileChange(ev){
            let UpFile = ev.target.files[0];
            this.DoUploadFile(UpFile);           
        },
        DoUploadFile(UpFile){
            let formData  = new FormData();
            formData.append('file', UpFile);
            formData.append('lang', this.SiteItem.lang);
            SiteContentService.SiteContentCoverAdd(formData)
                .then(res => {
                    this.SiteItem.homebanner = res.data;
                })
                .catch(err => {
                    this.$swal({
                        title: "Save Preview Image Error!!!",
                        html: err,
                        icon: "error"
                    })
                });
        },
        GetFullImageUrl(img_url){
           return this.$bkURL +"imgs/"+img_url;
        },
    },
    mounted(){
        this.GetSiteContent();
    }
}
</script>

<style scoped>
.img-language{
    max-width: 50px;
    height: auto;
}

.img-language-mini{
    max-width: 15px;
    height: auto;
}

.img-cover{
    width: 100%;
    height: auto;
}
</style>

<style>
@import "~vue2-editor/dist/vue2-editor.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>