<template>
    <div>
        <div class="coach align-items-stretch">
            <div class="img w-100" :style="'background-image: url('+(Pic != '' && Pic != null ? (imgApiURL + Pic) : imageDefault)+');'"></div>
            <!-- <a href="#" class="img w-100" :style="'background-image: url('+(Pic != '' && Pic != null ? (imgApiURL + Pic) : imageDefault)+');'"></a> -->
            
            <div class="text bg-white p-4 text-center">
                <span class="subheading">{{Church}}</span>
                <h3><a href="#">{{Name}} {{Surname}} ({{Nickname}})</a></h3>

                <div class="social-media">
                    <p class="d-flex text-center">
                        <a href="#" class="d-flex justify-content-center align-items-center text-center">
                            <span class="fab fa-facebook text-center"></span>
                            <!-- {{Facebook}} -->
                        </a>
                        <a href="#" class="d-flex justify-content-center align-items-center">
                            <span class="fab fa-instagram"></span>
                            <!-- {{IG}} -->
                        </a>
                    </p>
                </div>

                <div v-if="MenuEnable == true">
                    <base-button type="info" class="float-left" @click="itemEdit()"><i class="fa fa-pen"></i> Edit</base-button>
                    <base-button type="danger" class="float-right" @click="itemDelete()"><i class="fa fa-trash"></i> Delete</base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from '../components/BaseButton.vue'
import EventBus from '../Event-Bus.vue'

export default {
    components: { BaseButton },
    props:{
        Name:String,
        Surname:String,
        Nickname:String,
        Church:String,
        Pic:String,
        IG:String,
        Facebook:String,
        MenuEnable:Boolean,
        Item:Object
    },
    data(){
        return{
            imageDefault: require('../assets/img/image_not_available.png'),
            imgApiURL: this.$bkURL+"imgs/"
        }
    },
    methods:{
        itemEdit(){
            EventBus.$emit("TeacherReqEdit", this.Item);
        },
        itemDelete(){
            this.$swal({
                            icon: 'question',
                            title: 'Delete Teacher',
                            text: 'Are you sure to delete '+this.Item.name+' '+ this.Item.surname +'?',
                            showCloseButton: false,
                            showCancelButton: true,
                            focusConfirm: false,
                            confirmButtonText: '<i class="fa fa-trash"></i> Delete',
                            confirmButtonColor: '#dc3545',  
                            cancelButtonText: '<i class="fa fa-times"></i> Cancel',
                            cancelButtonColor: '#6c757d'
                        })
                .then((result) => {
                    if(result.isConfirmed){
                        EventBus.$emit("TeacherReqDelete", this.Item);
                    }
                });
        }
    }
}
</script>

<style scoped>

</style>