<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    ClassGets(){
        return axios.get(Vue.prototype.$bkURL + "classes");
    },
    ClassGetbyName(clname){
        return axios.get(Vue.prototype.$bkURL + "class/name?key="+clname);
    },
    ClassAdd(cl){
        return axios.post(Vue.prototype.$bkURL + "class", cl);
    },
    ClassUpdate(cl){
        return axios.patch(Vue.prototype.$bkURL + "class", cl);
    },
    ClassDelete(clid){
        return axios.delete(Vue.prototype.$bkURL + "class?id="+clid);
    },
    ClassBannerAdd(fdata){
        return axios.post(Vue.prototype.$bkURL + "class/banner", fdata);
    }
}
</script>