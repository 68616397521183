<template>
    <div>
        <!-- Main Content -->
        <div class="container-fluid">
            <!-- Header Menu -->
            <div class="row">
                <div class="col-sm-12">
                    <h6 class="h2 d-inline-block text-dark mt-2 mb-0">
                        <i :class="'ni '+PageIcon+' mr-1'"></i>{{PageTitle}}
                    </h6>

                    <base-button class="ml-2 mb-3" size="sm" type="info" @click="NewItem()">
                        <i class="fa fa-plus-circle"></i>{{MenuNewLabel}}
                    </base-button>
                </div>
            </div>

            <!-- Table Data -->
            <div class="row mt-2">
                <div class="col-sm-3" v-for="(cl,index) in Items" :key="index">
                    <cl-card
                        v-bind:Name="cl.name"
                        v-bind:Banner="cl.pic"
                        v-bind:MenuEnable="true"
                        v-bind:Item="cl"
                    >

                    </cl-card>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <modal :show="ModalEditOpen" size="lg">
            <div class="container-fluid">
                <h4 class="text-dark mb-1">{{ModalEditTitle}} Add/Edit</h4>

                <div class="row">
                    <div class="col-sm-4 text-center">
                        <label class="form-control-label">Preview Pic</label>
                        <img :src="EditItem.pic != '' && EditItem.pic != null ? GetFullImageUrl(EditItem.pic) : NoImagePath" class="img w-100"/>
                        <input type="file" @change="PreviewFileChange" class="form-control" accept="image/png, image/jpeg">
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label form-control-label pull-right">Class Name</label>
                            <div class="col-sm-9">
                                <base-input v-model="EditItem.name"></base-input>
                            </div>
                        </div>
                        
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <div class="row">
                                    <label class="col-sm-12 form-control-label">StartDate</label>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <base-input type="datetime-local" v-model="EditItem.startdate" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="row">
                                    <label class="col-sm-12 form-control-label">EndDate</label>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <base-input type="datetime-local" v-model="EditItem.enddate" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <label class="form-control-label col-sm-12">Category</label>
                            <div class="col-sm-8">
                                <base-input v-if="EnableNewCategory == false && optCategory.length > 1">
                                    <select class="form-control" v-model="EditItem.category">
                                        <option v-for="(op, index) in optCategory" :key="index"
                                            v-bind:value="op.value"
                                        >
                                            {{op.text}}
                                        </option>
                                    </select>
                                </base-input>
                                <base-input v-else placeholder="Category" v-model="NewCategory"></base-input>                                
                            </div>
                            <div class="col-sm-4">
                                <base-checkbox class="mb-3" v-model="EnableNewCategory">
                                    New Category
                                </base-checkbox>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-7">
                                <label class="form-control-label col-sm-12">Content Mode</label>
                                <b-form-group class="ml-4">
                                    <b-form-radio v-model="EditItem.fulldescmode" name="rdo-contentmode" value="external">External URL</b-form-radio>
                                    <b-form-radio v-model="EditItem.fulldescmode" name="rdo-contentmode" value="local">Local Content</b-form-radio>
                                </b-form-group>
                            </div>

                            <div class="col-sm-5">
                                <base-input label="Index" type="number" v-model.number="EditItem.idx" />
                            </div>
                        </div>

                        
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h5 class="form-control-label text-gray">
                            {{EditItem.fulldescmode == 'external' ? "Content Link" : "Local Content"}}

                            <base-button v-if="EditItem.fulldescmode != 'external'" size="sm" class="float-right" @click="ShowPreviewLocalContent = !ShowPreviewLocalContent">
                                {{ShowPreviewLocalContent? 'Hide' : 'Show'}} Preview
                            </base-button>
                        </h5>                        
                    </div>
                </div>

                <div class="row" v-if="EditItem.fulldescmode != 'local'">
                    <div class="col-sm-12">
                        <div class="form-group row">
                            <div class="col-sm-3">
                                <label class="col-form-label form-control-label float-right">External URL</label>
                            </div>
                            <div class="col-sm-9">
                                <base-input v-model="EditItem.exturl"></base-input>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="row mb-1" v-else>
                    <div class="col-sm-12 text-center">
                        <div class="row">
                            <div :class="ShowPreviewLocalContent ? 'col-sm-6' : 'col-sm-12'">
                                <vue-editor v-model="EditItem.htmldesc" :customModules="customModulesForEditor" :editorOptions="editorSettings" />
                            </div>
                            <div v-if="ShowPreviewLocalContent == true" class="col-sm-6">
                                <div v-html="EditItem.htmldesc"></div>
                            </div>
                        </div>
                    </div>                    
                </div>

                <div class="row mt-1 justify-content-end">
                    <base-button @click="SaveItem()" type="success"><i class="fa fa-save"></i> Save</base-button>
                    <base-button @click="CancelSaveItem()" type="dark"><i class="fa fa-times"></i> Close</base-button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import BaseButton from '../components/BaseButton.vue';
import ClassService from '../services/ClassService.vue';
import ClassCard from '../component_thaicma/ClassCard.vue';
import EventBus from '../Event-Bus.vue';
import { VueEditor } from 'vue2-editor';
import ImageResize from "quill-image-resize-vue";
import BaseInput from '../components/Inputs/BaseInput.vue';

export default {
    components: {
        BaseButton,
        "cl-card":ClassCard,
        VueEditor,
        BaseInput
    },
    data(){
        return{
            PageTitle: "Traning & Seminar Manage",
            PageIcon: "ni-book text-info",            
            MenuNewLabel: "New Seminar",
            Items:[],
            EditItem:{
                name: "",
                category: "",
                pic: null,
                htmldesc: "",
                startdate: null,
                enddate: null,
                fulldescmode: "external",
                exturl: "",
                idx: 1
            },
            NewCategory:"",
            EnableNewCategory:false,
            KeyItem: "clid",
            ModalEditTitle: "Seminar",
            ModalEditOpen: false,
            SubKeys:[
                "name"
            ],
            NoImagePath: require("@/assets/img/image_not_available.png"),
            ShowPreviewLocalContent:false,
            customModulesForEditor: [
                { alias: "imageResize", module: ImageResize }
            ],
            editorSettings: {
                modules: {
                    imageResize: {}
                }
            },
            optCategory:[
                {text:"--Category--", value:""}
            ]
        }
    },
    methods:{
        GetItemLists(){
            ClassService.ClassGets()
                .then(res => {
                    this.Items = [];
                    this.Items = res.data;

                    this.optCategory = [ {text:"--Category--", value:""} ];
                    let _Items = this.Items;
                    let lookup = {};
                    for (var item, i = 0; item = _Items[i++];) {
                        var _cate = item.category;

                        if (!(_cate in lookup) && _cate != "") {
                            lookup[_cate] = 1;
                            this.optCategory.push( {text:_cate, value:_cate});
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
        NewItem(){
            this.EnableNewCategory = false;
            this.NewCategory = false;
            
            this.EditItem ={
                name: "",
                category: "",
                pic: null,
                htmldesc: "",
                startdate: null,
                enddate: null,
                fulldescmode: "",
                exturl: "",
                idx: 1
            };
            if(this.Items.length > 0){
                let LastIdx = Math.max.apply(Math, this.Items.map(function(f) { return f.idx; }));
                this.EditItem.idx = LastIdx + 1;
            }

            if(this.optCategory.length <= 1){
                this.EnableNewCategory = true;
            }
            this.ModalEditOpen = true;
        },
        ValidateData(){
            let IsOK = false;
            let Notice = "";

            if(this.EnableNewCategory == true)
                this.EditItem.category = this.NewCategory;

            if(this.EditItem.name == "")
                Notice += "Please insert <b class='text-danger'>Class Name</b><br/>";
            
            if(this.EditItem.idx <= 0)
                Notice += "Index must greater than <b class='text-danger'>Zero</b>"

            if(this.EditItem.startdate == null)
                Notice += "Please insert <b class='text-danger'>Start Date</b><br/>"

            if(this.EditItem.enddate == null)
                Notice += "Please insert <b class='text-danger'>End Date</b><br/>"

            if(this.EditItem.category == "")
                Notice += "Please insert <b class='text-danger'>Category</b><br/>";

            if(this.EditItem.fulldescmode == '')
                Notice += "Please insert <b class='text-danger'>Content Mode</b><br/>"            
            
            if(this.EditItem.fulldescmode == "external"){
                if(this.EditItem.exturl == ""){
                    Notice += "Please insert <b class='text-danger'>External URL</b><br/>" 
                }

                if(!this.EditItem.exturl.startsWith('http')){
                    Notice += "External URL invalid!!! plase input like <b class='text-warning'>https://google.com</b> " 
                }
            }
            
            if(Notice != ""){
                this.$swal({
                    title: this.ModalEditTitle +" invalid data!!!",
                    html: Notice,
                    icon: "warning"
                })
                IsOK = false;
            }
            else{
                IsOK = true;
            }

            return IsOK;
        },
        SaveItem(){
            let IsOK = this.ValidateData();

            if(IsOK == true){
                if(this.EditItem[this.KeyItem] == null || this.EditItem[this.KeyItem] == ""){
                    ClassService.ClassAdd(this.EditItem)
                        .then(res => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.ModalEditOpen = false;
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
                else{
                    ClassService.ClassUpdate(this.EditItem)
                        .then(res => {
                            this.$swal({
                                title: "Update "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.ModalEditOpen = false;
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Update "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
            }
        },
        CancelSaveItem(){
            this.ModalEditOpen = false;
        },
        DeleteItem(ItemKey){
            ClassService.ClassDelete(ItemKey)
                        .then(res => {
                            this.$swal({
                                title: "Delete "+this.ModalEditTitle,
                                html: res.data,
                                icon: "success"
                            });
                            this.GetItemLists();
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Delete "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
        },
        DateTimeToInput(dt){
            let result = "";
            let Temp = new Date(dt);
            // yyyy-MM-ddThh:mm
            let year = Temp.getFullYear();
            let month = ('0' + (Temp.getMonth() + 1)).slice(-2);
            let date = ('0' + (Temp.getDate())).slice(-2);
            let hour = ('0' + Temp.getHours()).slice(-2);
            let minute = ('0' + Temp.getMinutes()).slice(-2);

            result = year+"-"+month+"-"+date+"T"+hour+":"+minute;
            // console.log(result);
            return result;
        },
        GetFullImageUrl(img_url){
           return this.$bkURL +"imgs/"+img_url;
        },
        PreviewFileChange(ev){
            console.log(ev);
            let UpFile = ev.target.files[0];
            if(this.EditItem.clid == null){
                let IsOK = this.ValidateData();
                if(IsOK == true){
                    ClassService.ClassAdd(this.EditItem)
                        .then(res => {            
                            console.log(res.data);                                            
                            ClassService.ClassGetbyName(this.EditItem.name)
                                .then(res2 => {
                                    // console.log(res2.data);
                                    let SavedItem = res2.data;
                                    this.EditItem = SavedItem;
                                    this.EditItem.startdate = this.DateTimeToInput(SavedItem.startdate);
                                    this.EditItem.enddate = this.DateTimeToInput(SavedItem.enddate);
                                    this.DoUploadFile(UpFile);
                                })
                                .catch(err2 => {
                                    this.$swal({
                                        title: "Save "+this.ModalEditTitle+" Error!!!",
                                        html: err2,
                                        icon: "error"
                                    });
                                });
                        })
                        .catch(err => {
                            this.$swal({
                                title: "Save "+this.ModalEditTitle+" Error!!!",
                                html: err,
                                icon: "error"
                            });
                        });
                }
            }
            else{
                this.DoUploadFile(UpFile);
            }            
        },
        DoUploadFile(UpFile){
            let formData  = new FormData();
            formData.append('file', UpFile);
            formData.append('clid', this.EditItem.clid);
            ClassService.ClassBannerAdd(formData)
                .then(res => {
                    this.EditItem.pic = res.data;
                })
                .catch(err => {
                    this.$swal({
                        title: "Save Preview Image Error!!!",
                        html: err,
                        icon: "error"
                    })
                });
        }
    },
    mounted(){
        this.GetItemLists();
        
        EventBus.$on("ClassReqEdit", (EditData) => {
            // console.log(EditData);
            this.EditItem = JSON.parse(JSON.stringify(EditData));
            this.EditItem.startdate = this.DateTimeToInput(this.EditItem.startdate);
            this.EditItem.enddate = this.DateTimeToInput(this.EditItem.enddate);
            if(this.optCategory.length <= 1){
                this.EnableNewCategory = true;
            }
            this.ModalEditOpen = true;
        });

        EventBus.$on("ClassReqDelete", (DeleteItem) => {
            // console.log(DeleteItem);
            let ItemKey = DeleteItem[this.KeyItem];
            this.DeleteItem(ItemKey);
        });
    },
    beforeDestroy(){
        EventBus.$off("ClassReqEdit");
        EventBus.$off("ClassReqDelete");
    }
    
}
</script>

<style scoped>
@import "~vue2-editor/dist/vue2-editor.css";
</style>