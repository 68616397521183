<template>
    <div>
        <div class="classes w-100 ftco-animate fadeInUp ftco-animated">
            <a href="#" class="img w-100" :style="'background-image: url('+(Banner != '' ? (imgApiURL + Banner) : imageDefault)+');'"></a>
            <div class="text w-100 text-center">
              <div class="meta">
                <p>
                  <a href="#"><i class="ni ni-calendar-grid-58 mr-1"></i> {{StartDate}}</a> <br>
                  <a href="#" class="meta-chat"><i class="ni ni-time-alarm"></i> {{StartTime}}</a>
                </p>
              </div>
              <h3><a href="#">{{Name}}</a></h3>

              <div v-if="MenuEnable == true">
                  <base-button type="info" class="float-left" @click="classEdit()"><i class="fa fa-pen"></i> Edit</base-button>
                  <base-button type="danger" class="float-right" @click="classDelete()"><i class="fa fa-trash"></i> Delete</base-button>
              </div>

            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from '../components/BaseButton.vue'
import EventBus from '../Event-Bus.vue'

export default {
    components: { BaseButton },
    props:{
        Name:String,
        Banner:String,
        TargetURL:String,
        MenuEnable:Boolean,
        Item:Object
    },
    data(){
        return{
            imageDefault: require('../assets/img/image_not_available.png'),
            imgApiURL: this.$bkURL+"imgs/",
            StartDate:"",
            StartTime:""
        }
    },
    methods:{
        classEdit(){
            EventBus.$emit("ClassReqEdit", this.Item);
        },
        classDelete(){
            this.$swal({
                            icon: 'question',
                            title: 'Delete Seminar',
                            html: '<h5>Are you sure to delete <b class="text-danger">'+this.Item.name+'</b>?</h5>',
                            showCloseButton: false,
                            showCancelButton: true,
                            focusConfirm: false,
                            confirmButtonText: '<i class="fa fa-trash"></i> Delete',
                            confirmButtonColor: '#dc3545',  
                            cancelButtonText: '<i class="fa fa-times"></i> Cancel',
                            cancelButtonColor: '#6c757d'
                        })
                .then((result) => {
                    if(result.isConfirmed){
                        EventBus.$emit("ClassReqDelete", this.Item);
                    }
                });
        },
        itemToDateTime(){
            let Temp = new Date(this.Item.startdate);
            
            let year = Temp.getFullYear();
            let month = ('0' + (Temp.getMonth() + 1)).slice(-2);
            let date = ('0' + Temp.getDate()).slice(-2);
            let hour = ('0' + Temp.getHours()).slice(-2);
            let minute = ('0' + Temp.getMinutes()).slice(-2);
            
            this.StartDate = year+"-"+month+"-"+date;
            this.StartTime = hour+":"+minute;
        },
    },
    mounted(){
        this.itemToDateTime();
    }
}
</script>

<style scoped>

</style>