import DashboardLayout from '@/layouts/DashboardLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';

const routes = [      
    {
        path: "/",
        redirect: "/Login",
        component: AuthLayout,
        children: [
        {
            path: "/Login",
            name: "Login",
            component: () => import("./pages/LoginPage.vue"),
            meta: {
            title: "หน้าแรก"
            }
        },
        ]
    },
    {
        path: "/newsmanage",
        redirect: "/newsmanage",
        component: DashboardLayout,
        children: [
            {
                path: "/newsmanage",
                name: "newsmanage",
                component: () => import("./pages/BlogManagePage.vue"),
                meta: {
                title: "News Manage Page"
                }
            },
        ]
    },
    {
        path: "/seminarmanage",
        redirect: "/seminarmanage",
        component: DashboardLayout,
        children: [
            {
                path: "/seminarmanage",
                name: "seminarmanage",
                component: () => import("./pages/ClassManagePage.vue"),
                meta: {
                title: "Seminar Manage Page"
                }
            },
        ]
    },
    {
        path: "/churchmanage",
        redirect: "/churchmanage",
        component: DashboardLayout,
        children: [
            {
                path: "/churchmanage",
                name: "churchmanage",
                component: () => import("./pages/ChurchManagePage.vue"),
                meta: {
                title: "Church Manage Page"
                }
            },
        ]
    },
    {
        path: "/mbtypemanage",
        redirect: "/mbtypemanage",
        component: DashboardLayout,
        children: [
            {
                path: "/mbtypemanage",
                name: "mbtypemanage",
                component: () => import("./pages/MemberTypeManagePage.vue"),
                meta: {
                title: "Member type Manage Page"
                }
            },
        ]
    },
    {
        path: "/rbchmanage",
        redirect: "/rbchmanage",
        component: DashboardLayout,
        children: [
            {
                path: "/rbchmanage",
                name: "rbchmanage",
                component: () => import("./pages/RespChurchManagePage.vue"),
                meta: {
                title: "Responsibility Church Manage Page"
                }
            },
        ]
    },
    {
        path: "/vocalmanage",
        redirect: "/vocalmanage",
        component: DashboardLayout,
        children: [
            {
                path: "/vocalmanage",
                name: "vocalmanage",
                component: () => import("./pages/VocalManagePage.vue"),
                meta: {
                title: "Vocal Manage Page"
                }
            },
        ]
    },
    {
        path: "/sitemanage",
        redirect: "/sitemanage",
        component: DashboardLayout,
        children: [
            {
                path: "/sitemanage",
                name: "sitemanage",
                component: () => import("./pages/SiteManagePage.vue"),
                meta: {
                title: "Site Manage Page"
                }
            },
        ]
    },
    {
        path: "/teachermanage",
        redirect: "/teachermanage",
        component: DashboardLayout,
        children: [
            {
                path: "/teachermanage",
                name: "teachermanage",
                component: () => import("./pages/TeacherManagePage.vue"),
                meta: {
                title: "Teacher Manage Page"
                }
            },
        ]
    },
    {
        path: "/membermanage",
        redirect: "/membermanage",
        component: DashboardLayout,
        children: [
            {
                path: "/membermanage",
                name: "membermanage",
                component: () => import("./pages/MemberManagePage.vue"),
                meta: {
                title: "Member Manage Page"
                }
            },
        ]
    },
    {
        path: "/choirministrymanage",
        redirect: "/choirministrymanage",
        component: DashboardLayout,
        children: [
            {
                path: "/choirministrymanage",
                name: "choirministrymanage",
                component: () => import("./pages/ChoirMinstryManagePage.vue"),
                meta: {
                    title: "Choir Ministry Manage Page"
                }
            },
        ]
    }
];

export default routes;