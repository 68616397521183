<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    ChurchGets(){
        return axios.get(Vue.prototype.$bkURL + "churchs");
    },
    ChurchAdd(ch){
        return axios.post(Vue.prototype.$bkURL + "church", ch);
    },
    ChurchUpdate(ch){
        return axios.patch(Vue.prototype.$bkURL + "church", ch);
    },
    ChurchDelete(ch_id){
        return axios.delete(Vue.prototype.$bkURL + "church?id="+ch_id);
    }
}
</script>