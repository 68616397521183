var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',{attrs:{"logo":_vm.Logo,"autoClose":true,"title":"ThaiCMA","shortTitle":"SV"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Site',
          icon: 'ni ni-planet text-danger',
          path: '/sitemanage'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'News',
          icon: 'ni ni-collection',
          path: '/newsmanage'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Traning&Seminar',
          icon: 'ni ni-books text-info',
          path: '/seminarmanage'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'CMA/Angel Choir',
          icon: 'ni ni-paper-diploma text-warning',
          path: '/choirministrymanage'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Members',
          icon: 'ni ni-single-02 text-success',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Member', path: '/membermanage' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Church', path: '/churchmanage' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'MemberType', path: '/mbtypemanage' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Responsibilty', path: '/rbchmanage' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Vocal', path: '/vocalmanage' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'User',
          icon: 'ni ni-circle-08 text-dark',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'SignOut', path: '/Login' }}})],1)],1)],2),_c('div',{staticClass:"main-content"},[_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }