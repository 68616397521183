<template>
    <div></div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
    BlogGets(){
        return axios.get(Vue.prototype.$bkURL + "Blogs");
    },
    BlogGetbyName(blgname){
        return axios.get(Vue.prototype.$bkURL + "Blog/name?key="+blgname);
    },
    BlogAdd(bg){
        return axios.post(Vue.prototype.$bkURL + "Blog", bg);
    },
    BlogUpdate(bg){
        return axios.patch(Vue.prototype.$bkURL + "Blog", bg);
    },
    BlogDelete(bid){
        return axios.delete(Vue.prototype.$bkURL + "Blog?id="+bid);
    },
    BlogBannerAdd(fdata){
        return axios.post(Vue.prototype.$bkURL + "Blog/banner", fdata);
    },
    BlogGalleryGet(bid){
        return axios.get(Vue.prototype.$bkURL + "Blog/gallery?id="+bid);
    },
    BlogGalleryAdd(fdata){
        return axios.post(Vue.prototype.$bkURL + "Blog/gallery", fdata);
    },
    BlogGalleryDelete(bid,item){
        return axios.delete(Vue.prototype.$bkURL + "Blog/gallery?id="+bid+"&item="+item);
    }
}
</script>